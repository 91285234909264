import ServerCall from "../../serverCall";

const serverCall = ServerCall();
const getAllCategory = () => {
  const response = serverCall.customAxios.get(`/category/get-all-category`);
  return response;
};

const CategoryService = { getAllCategory };

export default CategoryService;

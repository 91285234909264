import React, { useEffect, useState } from "react";
import Logo from "../../assets/svg/logo/light.svg";
import { TiSocialFacebook } from "react-icons/ti";
import { FaInstagram, FaLinkedinIn, FaXTwitter } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const [role, setRole] = useState("");
  const [userData, setUserData] = useState("");
  // Give Help Get Help
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
    setIsLoggedIn(Boolean(storedIsLoggedIn));

    const storedRole = localStorage.getItem("payyro-store");
    const role = JSON.parse(storedRole);
    setRole(role?.role);
    setUserData(role);
  }, []);

  return (
    <div className="bg-primary text-white rounded-t-40">
      <div className="border-b border-blue/40">
        <div className="containers">
          <div className="flex lg:flex-row flex-col justify-between items-start gap-10 lg:gap-4 py-10">
            <div className="flex flex-col flex-wrap gap-5 lg:max-w-[386px]">
              <div onClick={() => navigate("/")} className="cursor-pointer">
                <img src={Logo} alt="Logo" className="h-8 md:h-9" />
              </div>
              <p className="text-white/60 text-base">
                Lorem ipsum dolor sit amet consectetur. Id cras lectus vel ac
                aliquam quam commodo dui. Lobortis vel turpis luctus enim.
              </p>
            </div>
            <div className="grid md:grid-cols-2 gap-4 lg:gap-16 justify-between">
              <div className="flex justify-center">
                <div className="flex flex-col gap-4 md:gap-2.5">
                  <div
                    onClick={() => navigate("/help-request")}
                    className="hover:text-blue duration-200 cursor-pointer"
                  >
                    <p className="p-2 md:p-2.5 text-sm md:text-base font-bold">
                      Help Requests
                    </p>
                  </div>
                  <a
                    href={isLoggedIn && role ? "/home/#" : "#"}
                    className="hover:text-blue duration-200"
                  >
                    <p className="p-2 md:p-2.5 text-sm md:text-base font-bold">
                      Contact Us
                    </p>
                  </a>
                  <a
                    href={isLoggedIn && role ? "/home/#works" : "#works"}
                    className="hover:text-blue duration-200"
                  >
                    <p className="p-2 md:p-2.5 text-sm md:text-base font-bold">
                      How it Works
                    </p>
                  </a>
                </div>
              </div>
              <div className="flex justify-center">
                <div className="flex flex-col gap-4 md:gap-2.5">
                  <a
                    href={isLoggedIn && role ? "/home/#faq" : "#faq"}
                    className="hover:text-blue duration-200"
                  >
                    <p className="p-2 md:p-2.5 text-sm md:text-base font-bold">
                      FAQ
                    </p>
                  </a>
                  <a
                    href={isLoggedIn && role ? "/home/#" : "#"}
                    className="hover:text-blue duration-200"
                  >
                    <p className="p-2 md:p-2.5 text-sm md:text-base font-bold">
                      Privacy Policy
                    </p>
                  </a>
                  <a
                    href={isLoggedIn && role ? "/home/#" : "#"}
                    className="hover:text-blue duration-200"
                  >
                    <p className="p-2 md:p-2.5 text-sm md:text-base font-bold">
                      Terms of Use
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div>
              <h6 className="text-base">Follow us on Social Media</h6>
              <div className="mt-4 flex items-center gap-4">
                <a href={isLoggedIn && role ? "/home/#" : "#"}>
                  <div className="h-8 w-8 flex items-center justify-center bg-white/10 rounded-full hover:bg-blue duration-200">
                    <TiSocialFacebook className="text-xl" />
                  </div>
                </a>
                <a href={isLoggedIn && role ? "/home/#" : "#"}>
                  <div className="h-8 w-8 flex items-center justify-center bg-white/10 rounded-full hover:bg-blue duration-200">
                    <FaXTwitter className="" />
                  </div>
                </a>
                <a href={isLoggedIn && role ? "/home/#" : "#"}>
                  <div className="h-8 w-8 flex items-center justify-center bg-white/10 rounded-full hover:bg-blue duration-200">
                    <FaInstagram className="" />
                  </div>
                </a>
                <a href={isLoggedIn && role ? "/home/#" : "#"}>
                  <div className="h-8 w-8 flex items-center justify-center bg-white/10 rounded-full hover:bg-blue duration-200">
                    <FaLinkedinIn className="" />
                  </div>
                </a>
                <a href={isLoggedIn && role ? "/home/#" : "#"}>
                  <div className="h-8 w-8 flex items-center justify-center bg-white/10 rounded-full hover:bg-blue duration-200">
                    <IoLogoYoutube className="" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-6">
        <p className="text-xs md:text-sm text-center">
          ©2024 Copyright Payyro. All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;

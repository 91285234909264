import React, { useEffect, useState } from "react";
import FAQService from "../service/faq.service";
import Toaster from "./toaster";
import Loader from "../../assets/loader.gif";

const Accoudion = ({ setIsFAQ }) => {
  const [data, setData] = useState([]);
  console.log("🚀  Accoudion  data:", data);
  const [expanded, setExpanded] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const showParagraph = (content, index) => {
    const paragraph = content?.split("\r\n\r\n");
    return (
      <>
        {paragraph &&
          paragraph?.map((item, index) => (
            <p key={index} className="tab:pt-4 pt-3">
              {item}
            </p>
          ))}
      </>
    );
  };

  const getFAQ = async () => {
    try {
      const response = await FAQService.getAllFaq();
      setData(response.data.data);
      setIsFAQ(response?.data?.data?.length > 0 ? true : false);
    } catch (error) {
      Toaster(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFAQ();
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center h-[calc(100vh-270px)] w-[100vw] lg:w-[calc(100vw-400px)]">
          <img src={Loader} alt="Loader" className="h-16 my-3" />
        </div>
      ) : (
        <>
          {data?.length > 0 ? (
            <div className="dashboard-card tab:pb-26 pb-4 px-4 tab:px-26">
              <div className="bg-white w-full mx-auto ">
                <ul className="shadow-box">
                  {data.map((item, index, array) => (
                    <div
                      key={index}
                      className={`border-b border-primary/10 mx-auto pt-5 pb-5 tab:pt-30 tab:pb-[26px]`}
                    >
                      <h2 className="m-0 hover:bg-secondaryLight">
                        <button
                          type="button"
                          className="flex items-center justify-between w-full text-left font-semibold gap-4"
                          onClick={() =>
                            setExpanded((prev) => {
                              const newExpanded = [...prev];
                              newExpanded[index] =
                                newExpanded[index] === true ? false : true;
                              return newExpanded;
                            })
                          }
                          aria-expanded={expanded[index]}
                        >
                          <div className="flex items-center gap-5">
                            <h6 className="tab:px-1.5 tab:py-1.5 tab:h-8 tab:w-8 h-7 w-7 rounded-md tab:text-sm text-xs bg-primary/10 flex items-center justify-center font-bold">
                              Q{index + 1}
                            </h6>
                            <span className="tab:text-base text-sm">
                              {item.question}
                            </span>
                          </div>
                          <div className="p-1.5 md:p-[7px] border border-primary rounded-md">
                            <svg
                              className="text-[#00253F] shrink-0"
                              width="14"
                              height="14"
                              viewBox="0 0 8 8"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                y="3.5"
                                width="8"
                                height="1"
                                rx="0.5"
                                fill="currentColor"
                                className={`transform origin-center transition duration-200 ease-out ${
                                  expanded[index] ? "!rotate-180" : ""
                                }`}
                              />
                              <rect
                                y="3.5"
                                width="8"
                                height="1"
                                rx="0.5"
                                fill="currentColor"
                                className={`transform origin-center rotate-90 transition duration-200 ease-out ${
                                  expanded[index] ? "!rotate-180" : ""
                                }`}
                              />
                            </svg>
                          </div>
                        </button>
                      </h2>
                      <div
                        className={`grid text-sm text-slate-600 overflow-hidden transition-all duration-300 ease-in-out ${
                          expanded[index]
                            ? "grid-rows-[1fr] opacity-100"
                            : "grid-rows-[0fr] opacity-0"
                        }`}
                      >
                        <div className="overflow-hidden">
                          {showParagraph(item.answer)}
                        </div>
                      </div>
                    </div>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <p
              className={`${
                data?.length ? "" : "w-full"
              } flex justify-center items-center p-4 font-semibold text-15`}
            >
              No FAQ Found
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default Accoudion;

import { createContext, useState, useEffect } from "react";
const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [role, setRole] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isToken, setIsToken] = useState("");

  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
    setIsLoggedIn(Boolean(storedIsLoggedIn));

    const storedRole = localStorage.getItem("role");
    setRole(storedRole);
  }, []);

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, setIsLoggedIn, role, setRole, isToken, setIsToken }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export { AuthContext, AuthProvider };

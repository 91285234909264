import { BrowserRouter, useNavigate } from "react-router-dom";
import "./App.css";
import "react-datepicker/dist/react-datepicker.css";
import PublicRoutes from "./router/public/routes";
import PrivateRoutes from "./router/private/route";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "./context/authContext";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedIn, setToken } from "./store/slice/auth";
import { LocalData } from "./app/common/Common";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function App() {
  const path = window.location.pathname;
  const { role } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const localData = LocalData();

  useEffect(() => {
    const token = localData?.token;
    const isLoggedIn = localStorage.getItem("isLoggedIn");

    if (token) {
      dispatch(setToken(token));
    }

    if (isLoggedIn) {
      dispatch(setLoggedIn(JSON.parse(isLoggedIn)));
    }
  }, [dispatch]);

  const { isLoggedIn } = useSelector((state) => state.auth);

  const isLoggedin =
    isLoggedIn || !!JSON.parse(localData !== null && localData?.token);

  useEffect(() => {
    const newRole = localData?.role;
    const basePath = process.env.REACT_APP_HOME_PAGE;

    if (
      (path.includes("/get-help/dashboard") ||
        path.includes("/get-help/user-get-help") ||
        path.includes("/get-help/my-help-request") ||
        path.includes("/get-help/user-profile") ||
        path.includes("/user-faqs") ||
        path.includes("/get-help/create-request") ||
        path.includes("/vendor/dashboard") ||
        path.includes("/vendor/help-request") ||
        path.includes("/vendor/upload-bulk-request") ||
        path.includes("/vendor/profile") ||
        path.includes("/vendor/edit-profile") ||
        path.includes("/give-help/dashboard") ||
        path.includes("/give-help/help") ||
        path.includes("/user-give/help-profile") ||
        path.includes("/user-give/help-request") ||
        path.includes("/user-give/help-payment") ||
        path.includes("/give-help/user-profile") ||
        path.includes("/edit-profile")) &&
      !isLoggedIn
    ) {
      navigate("/");
    }

    if (
      (newRole === "Get Help" ||
        role === "Get Help" ||
        newRole === "Give Help" ||
        role === "Give Help" ||
        newRole === "Vendor" ||
        role === "Vendor") &&
      (path === basePath ||
        path.startsWith(`${basePath}help-request`) ||
        path.startsWith(`${basePath}request-detail`) ||
        path.startsWith(`${basePath}user-profile`) ||
        path.startsWith(`${basePath}help-payment-form`) ||
        path === `${basePath}get-help` ||
        path.startsWith(`${basePath}vendor-solution`) ||
        path.startsWith(
          `${basePath}vendor-solutions/vendor-solutions-learn-more`
        ) ||
        path.startsWith(`${basePath}login`) ||
        path.startsWith(`${basePath}signup`) ||
        path.startsWith(`${basePath}forgot-password`) ||
        path.startsWith(`${basePath}vendor-signup`) ||
        path.startsWith(`${basePath}vendor-login`)) &&
      isLoggedIn
    ) {
      if (newRole === "Get Help" || role === "Get Help") {
        navigate("/get-help/dashboard");
      } else if (newRole === "Give Help" || role === "Give Help") {
        navigate("/give-help/dashboard");
      } else if (newRole === "Vendor" || role === "Vendor") {
        navigate("/vendor/dashboard");
      }
    }

    if (
      (newRole === "Get Help" || role === "Get Help") &&
      (path.includes("/give-help/dashboard") ||
        path.includes("/give-help/help") ||
        path.includes("/user-give/help-profile") ||
        path.includes("/user-give/help-request") ||
        path.includes("/user-give/help-payment") ||
        path.includes("/give-help/user-profile") ||
        path.includes("/vendor/dashboard") ||
        path.includes("/vendor/help-request") ||
        path.includes("/vendor/upload-bulk-request") ||
        path.includes("/vendor/edit-profile") ||
        path.includes("/vendor/profile")) &&
      isLoggedIn
    ) {
      navigate("/get-help/dashboard");
    }

    if (
      (newRole === "Give Help" || role === "Give Help") &&
      (path.includes("/vendor/dashboard") ||
        path.includes("/vendor/help-request") ||
        path.includes("/vendor/upload-bulk-request") ||
        path.includes("/vendor/profile") ||
        path.includes("/vendor/edit-profile") ||
        path.includes("/get-help/dashboard") ||
        path.includes("/get-help/user-get-help") ||
        path.includes("/get-help/my-help-request") ||
        path.includes("/get-help/user-profile") ||
        path.includes("/get-help/create-request")) &&
      isLoggedIn
    ) {
      navigate("/give-help/dashboard");
    }

    if (
      (newRole === "Vendor" || role === "Vendor") &&
      (path.includes("/give-help/dashboard") ||
        path.includes("/give-help/help") ||
        path.includes("/user-give/help-profile") ||
        path.includes("/user-give/help-request") ||
        path.includes("/user-give/help-payment") ||
        path.includes("/give-help/user-profile") ||
        path.includes("/get-help/dashboard") ||
        path.includes("/get-help/user-get-help") ||
        path.includes("/get-help/my-help-request") ||
        path.includes("/get-help/user-profile") ||
        path.includes("/get-help/create-request")) &&
      isLoggedIn
    ) {
      navigate("/vendor/dashboard");
    }
  }, [path]);

  return (
    <>
      <Elements stripe={stripePromise}>
        {/* <BrowserRouter basename={process.env.REACT_APP_HOME_PAGE}> */}
        {isLoggedIn ? (
          <PrivateRoutes isLoggedIn={isLoggedin} role={role} />
        ) : (
          <PublicRoutes isLoggedIn={isLoggedin} />
        )}
      </Elements>
      {/* </BrowserRouter> */}
    </>
  );
}

export default App;

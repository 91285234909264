import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import stripe from "../../assets/svg/stripe.svg";
import SlashInput from "./SlashInput";
import Loader from "../../assets/loader.gif";
import { HandleChangeAmount } from "./Common";

const AddAccount = ({
  open,
  handleClickClose,
  accountRef,
  accountFormik,
  accountIsLoading,
}) => {
  return (
    <div>
      {open && (
        <div className="fixed inset-0 z-[999] bg-black/50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div
              className="dashboard-card overflow-hidden max-w-[652px] w-full min-w-[350px]"
              ref={accountRef}
            >
              <div className="flex justify-between items-center b-b border-primary/20 tab:px-26 px-4 py-4 tab:py-5">
                <h5 className="tab:text-xl text-base">Add Account</h5>
                <div className="cursor-pointer" onClick={handleClickClose}>
                  <IoCloseOutline className="h-5 w-5" />
                </div>
              </div>
              <form
                className="tab:p-26 p-4 flex flex-col gap-4"
                onSubmit={accountFormik.handleSubmit}
              >
                <div>
                  <label>Account No.</label>
                  <SlashInput
                    name="accountNo"
                    type="text"
                    placeholder=""
                    formik={accountFormik}
                    onChange={(e) =>
                      HandleChangeAmount(
                        e.target.value,
                        "accountNo",
                        accountFormik
                      )
                    }
                  />
                </div>
                <div className="grid grid-cols-1 tab:grid-cols-2 gap-4">
                  <div>
                    <label>Bank</label>
                    <SlashInput
                      name="bank"
                      type="text"
                      placeholder=""
                      formik={accountFormik}
                    />
                  </div>
                  <div>
                    <label>Branch Code</label>
                    <SlashInput
                      name="branchCode"
                      type="text"
                      placeholder=""
                      formik={accountFormik}
                    />
                  </div>
                  <div>
                    <label>Account Holder Name</label>
                    <SlashInput
                      name="accountHolderName"
                      type="text"
                      placeholder=""
                      formik={accountFormik}
                    />
                  </div>
                  <div>
                    <label>IBAN</label>
                    <SlashInput
                      name="iban"
                      type="text"
                      placeholder=""
                      formik={accountFormik}
                      onChange={(e) =>
                        HandleChangeAmount(
                          e.target.value,
                          "iban",
                          accountFormik
                        )
                      }
                    />
                  </div>
                </div>
                <div className="flex justify-start items-center gap-3">
                  <input
                    type="checkbox"
                    id="Preferred"
                    className="custom-checkbox appearance-none cursor-pointer h-5 w-5 rounded-md border border-gray-300 checked:bg-success checked:border-success focus:outline-none "
                    name="isPreferred"
                    value={accountFormik.values.isPreferred}
                    onChange={accountFormik.handleChange}
                  />
                  <label
                    htmlFor="Preferred"
                    className="cursor-pointer text-base m-0"
                  >
                    Preferred Account
                  </label>
                </div>
                <div className="b-b border-primary/20"></div>
                <div>
                  <p className="text-center">
                    We do not store any card details and never disclose any user
                    information to anyone.
                  </p>
                </div>
                <div className="text-center">
                  <h6 className="text-base font-normal">Powered By</h6>
                  <div className="flex justify-center items-center">
                    <img src={stripe} />
                  </div>
                </div>
                <div className=" b-b border-primary/20 mb-26"></div>
                <div className="flex justify-end tab:py-5 tab:px-26 py-4 px-4">
                  {accountIsLoading ? (
                    <button
                      className="btn-big btn-primary h-[37px] w-[98px] tab:h-[49px] tab:w-[130px] flex justify-center items-center"
                      type="submit"
                    >
                      <img src={Loader} className="h-7 w-7" />
                    </button>
                  ) : (
                    <button
                      className="btn-big btn-primary h-[37px] w-[98px] tab:h-[49px] tab:w-[130px]"
                      type="submit"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddAccount;

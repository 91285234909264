import ServerCall from "../../serverCall";

const serverCall = ServerCall();

const getAllFaq = () => {
  const response = serverCall.customAxios.get(`/faq/getall`);
  return response;
};

const FAQService = { getAllFaq };

export default FAQService;

import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./layout/Footer";
import Header from "./layout/Header";

const PublicLayout = () => {
  return (
    <Fragment>
      <div className="">
        <Header />
        <div className="main-content side-content">
          <div className="main-container container-fluid">
            <div className="inner-body min-h-[calc(100vh-301px)]">
              <Outlet />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Fragment>
  );
};
export default PublicLayout;

import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import Textarea from "./textarea";

const UserBio = ({ bio, handleCloseBio, bioRef, formik }) => {
  return (
    <div>
      {bio && (
        <div className="fixed inset-0 z-[999] bg-black/50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div
              className="dashboard-card overflow-hidden max-w-[652px] w-full min-w-[350px]"
              ref={bioRef}
            >
              <form onSubmit={formik.handleSubmit}>
                <div className="flex justify-between items-center b-b border-primary/20 tab:px-26 px-4 py-4 tab:py-5">
                  <h5 className="tab:text-xl text-base">Bio</h5>
                  <div className="cursor-pointer" onClick={handleCloseBio}>
                    <IoCloseOutline className="h-5 w-5" />
                  </div>
                </div>
                <div className="tab:p-26 p-4 flex flex-col gap-4">
                  <div>
                    <label>Bio</label>
                    <Textarea
                      name="bio"
                      type="text"
                      placeholder=""
                      formik={formik}
                    />
                  </div>
                </div>
                <div className=" b-b border-primary/20 mb-26"></div>
                <div className="text-end tab:py-5 tab:px-26 py-4 px-4">
                  <button type="submit" className="btn-big btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserBio;

import React, { useState, useEffect } from "react";
import Logo from "../../assets/svg/Logo.svg";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoCloseOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import AvatarIcon from "../common/Avatar";

const Header = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [buttonName, setButtonName] = useState("menu");
  const [hasScrolled, setHasScrolled] = useState(false);

  const [role, setRole] = useState("");
  console.log("🚀  Header  role:", role);
  const [userData, setUserData] = useState("");
  console.log("🚀  Header  role:", role);
  // Give Help Get Help
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  console.log("🚀  Header  isLoggedIn:", isLoggedIn);

  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
    setIsLoggedIn(Boolean(storedIsLoggedIn));

    const storedRole = localStorage.getItem("payyro-store");
    console.log("🚀  useEffect  storedRole:", storedRole);
    const role = JSON.parse(storedRole);
    setRole(role?.role);
    setUserData(role);
  }, []);

  const handleProfileClick = () => {
    if (role === "Get Help") {
      console.log("🚀  handleProfileClick  role:", role);
      navigate("/get-help/dashboard");
    } else if (role === "Give Help") {
      console.log("🚀  handleProfileClick  role:", role);
      navigate("/give-help/dashboard");
    } else {
      navigate("/vendor/dashboard");
    }
  };

  const onMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
    setButtonName(buttonName === "menu" ? "close" : "menu");
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 100) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handelNavigate = (path) => {
    navigate("/login");
  };

  return (
    <header
      className={`fixed w-full z-100  py-5 ${
        hasScrolled ? "bg-transparent" : "bg-transparent h-auto"
      }`}
    >
      <div className="containers h-full flex items-center">
        <nav
          className={`shadow-header bg-lightGrey w-full flex items-center justify-between rounded-10 px-5 py-4 transition-all duration-500 ease-in-out ${
            hasScrolled ? "" : ""
          }`}
        >
          <div onClick={() => navigate("/home")} className="cursor-pointer">
            <img src={Logo} alt="Logo" className="h-9 md:h-[49px]" />
          </div>
          <div className="flex items-center gap-3">
            <div
              className={`navLinks bg-white lg:bg-lightGrey absolute top-0 md:static w-full md:w-auto mx-auto md:overflow-visible md:rounded-10 flex md:items-center gap-[1.5vw] ${
                isMenuOpen ? "right-0" : "-right-full"
              } md:right-0 h-screen md:h-auto overflow-y-auto md:py-0 transition-all -top-5 z-50`}
            >
              <div className="w-full p-4 md:p-0">
                <div className="md:hidden rounded-10 px-5 flex justify-between items-center bg-[#F1F7F9] w-full py-[22px]">
                  <div onClick={() => navigate("/")} className="cursor-pointer">
                    <img src={Logo} alt="Logo" className="h-9 md:h-[49px]" />
                  </div>
                  <div
                    onClick={onMenuToggle}
                    className="rounded p-1 border-2 border-primary/40"
                  >
                    <IoCloseOutline className="text-xl text-[#262626]" />
                  </div>
                </div>
                <ul className="flex md:flex-row flex-col md:items-center md:gap-2.5 w-full whitespace-nowrap md:justify-between py-7 md:py-0">
                  <li className="w-full text-center pr-3 md:pr-0 py-1 group">
                    <a
                      href={isLoggedIn && role ? "/home/#works" : "#works"}
                      className="px-9 py-4 md:p-0 w-full inline-block"
                    >
                      <span className="text-sm h-full py-4 px-9 font-bold text-primary">
                        How It Works
                      </span>
                    </a>
                  </li>
                  <li className="w-full text-center pr-3 md:pr-0 py-1 group">
                    <a
                      href={isLoggedIn && role ? "/home/#faq" : "#faq"}
                      className="px-9 py-4 md:p-0 w-full inline-block"
                    >
                      <span className="text-sm h-full py-4 px-9 font-bold text-primary">
                        FAQ
                      </span>
                    </a>
                  </li>
                  <li className="w-full text-center pr-3 md:pr-0 py-1 group">
                    <a
                      href={isLoggedIn && role ? "/home/#" : "#"}
                      className="px-9 py-4 md:p-0 w-full inline-block"
                    >
                      <span className="text-sm h-full py-4 px-9 font-bold text-primary">
                        Contact Us
                      </span>
                    </a>
                  </li>
                  {!isLoggedIn && !role ? (
                    <div className="flex flex-col sm:flex-row items-center gap-4 justify-center">
                      <div className="md:w-fit w-full inline-block">
                        <button
                          className="btn-header btn-outline-primary md:w-fit w-full"
                          onClick={() => handelNavigate("login")}
                        >
                          Login
                        </button>
                      </div>
                      <div className="md:w-fit w-full inline-block">
                        <button
                          className="btn-header btn-primary md:w-fit w-full "
                          onClick={() => handelNavigate("gethelp")}
                        >
                          Get Help
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div onClick={handleProfileClick}>
                      <AvatarIcon
                        size={36}
                        className="rounded-full cursor-pointer"
                        name={userData.name || userData.firstName}
                      />
                    </div>
                  )}
                </ul>
              </div>
            </div>
            <div
              onClick={onMenuToggle}
              className="rounded p-1 border-2 border-primary/40 md:hidden"
            >
              <RxHamburgerMenu className="text-xl text-[#262626]" />
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;

import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import banner from "../../../assets/svg/background/BANNER.png";

const EffectiveBio = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className=" h-[100vh] grid grid-cols-12">
        <div className="lg:col-span-5 hidden lg:inline-block">
          <div className="h-screen w-full sticky top-0 left-0">
            <img src={banner} alt="" className="h-full w-full object-cover" />
          </div>
        </div>
        <div className="col-span-12 lg:col-span-7 flex justify-center items-center h-full">
          <div className="w-full max-w-2xl mx-2">
            <div className="h-full">
              <div className="w-full">
                <div className="p-4 tab:px-26 tab:py-5 b-b flex items-center gap-4">
                  <div
                    className="rounded-full p-2 bg-black/80 text-white cursor-pointer"
                    onClick={() => navigate(-1)}
                  >
                    <IoMdArrowRoundBack />
                  </div>
                  <h6 className="text-base"> Tips for an Effective Bio</h6>
                </div>

                <div className=" rounded-lg p-6 md:p-10">
                  <p className="  mb-4 text-base">
                    It’s best to begin your About Me profile with a few short
                    sentences that contain the most important information. You
                    can think of this as a brief summary of exactly why you need
                    immediate financial help. Donors may feel more charitable if
                    it seems like you’ve exhausted all other options.
                  </p>
                  <p className="  mb-4 text-base">
                    After that, you can provide more background information that
                    will paint a broader picture of your life story.
                  </p>
                  <p className="  mb-4 text-base">
                    Your story should clearly explain the reason behind your
                    fundraiser, because this helps build trust with your donors.
                    A story that is vague may not inspire confidence. zed w/
                    Rare Condition” for inspiration.
                  </p>
                  <p className="  mb-4 text-base font-medium">
                    Be sure to include important information like:
                  </p>
                  <ul className="list-disc list-inside  mb-4 text-base">
                    <li>Who you are.</li>
                    <li>
                      Exactly why you are in financial distress. Listing a
                      budget is a great way to be transparent and gain your
                      donors’ trust.
                    </li>
                    <li>
                      Make sure your story isn’t too long or too short. We’ve
                      found writing a minimum of 400 words can help you gain
                      attention.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EffectiveBio;

import React, { useEffect, useRef, useState } from "react";
import banner from "../../../assets/svg/background/BANNER.png";
import { useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import AuthenticationService from "../../service/auth.service";
import Toaster from "../../common/toaster";
import { TiTick } from "react-icons/ti";
import SignUp2 from "./SignUp2";
import SignUp3 from "./SignUp3";
import UseDialogState from "../../../hooks/UseDialogState";
import UploadDocument from "../../user/gethelp/UploadDocument";
import Loader from "../../../assets/loader.gif";

const SetUpProfile = () => {
  const navigate = useNavigate();
  const { type } = useParams();
  const { state } = useLocation();

  const {
    openAddDocument,
    handleClickCloseAddDocument,
    handleClickOpenAddDocument,
  } = UseDialogState();
  const steps = type === "get" ? ["", ""] : [];
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedImages, setSelectedImages] = useState([]);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLatestProfile, setSelectedLatestProfile] = useState(null);
  const documentRef = useRef();
  const [uploadProgress, setUploadProgress] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [documentLoader, setDocumentLoader] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState([]);
  const [locationAddress, setLocationAddress] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  useEffect(() => {
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition(
        (position) => {
          getAddressFromCoordinates(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (error) => {
          Toaster("error", error.message);
        }
      );
    }
  }, []);

  const getAddressFromCoordinates = (lat, lng) => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.address) {
          const addressObject = {
            postalCode: data.address.postcode || "",
            state: data.address.state || "",
            country: data.address.country || "",
            city: data.address.city || data.address.state_district || "",
            line:
              data.address.road ||
              data.address.neighbourhood ||
              data.address.suburb ||
              data.address.town ||
              "",
          };
          setLocationAddress(addressObject);
        } else {
          Toaster("error", "No address found for the given coordinates.");
        }
      })
      .catch((error) => {
        console.error("Error fetching address:", error);
      });
  };

  const submit = async (values) => {
    setIsLoading(true);
    const { bio, address, latestProfileImage, profileImages, document, video } =
      values;

    const formData = new FormData();
    formData.append("bio", bio);
    formData.append("address", address);
    formData.append("latestProfileImage", latestProfileImage);

    if (locationAddress) {
      formData.append("postalCode", locationAddress.postalCode);
      formData.append("state", locationAddress.state);
      formData.append("country", locationAddress.country);
      formData.append("city", locationAddress.city);
      formData.append("line", locationAddress.line);
    }

    profileImages.forEach((item) => formData.append("profileImages", item));

    if (type === "get") {
      document.forEach((item) => formData.append("document", item));
      video.forEach((item) => formData.append("video", item));
      // formData.append("video", video);
    }

    try {
      if (type === "get") {
        const response = await AuthenticationService.setUpGetProfile(
          formData,
          state?.token
        );
        navigate("/login");
      } else if (type === "give") {
        const response = await AuthenticationService.setUpGiveProfile(
          formData,
          state?.token
        );
        navigate("/login");
      }
    } catch (error) {
      Toaster("error", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };
  const SUPPORTED_IMAGE_FORMATS = [
    "image/jpeg", // JPEG
    "image/png", // PNG
    "image/gif", // GIF
  ];

  const validationSchema = yup.object().shape({
    // bio: yup.string().required("About Me is required"),
    bio: type === "get" ? yup.string().required("About Me is required") : "",
    address:
      type === "get"
        ? yup.string().required("Address is required")
        : yup.string().required("Address is required"),
    /* latestProfileImage: yup
      .mixed()
      .test(
        "fileFormat",
        "Only image files are allowed (JPEG, PNG, GIF)",
        (value) => !value || SUPPORTED_IMAGE_FORMATS.includes(value.type)
      ), */

    /* profileImages: yup
      .array()
      .min(1, "At least one Profile image is required")
      .of(yup.mixed().required("Profile image is required")), */
    /* profileImages: yup
      .array()
      .of(
        yup
          .mixed()
          .test(
            "fileFormat",
            "Only image files are allowed (JPEG, PNG, GIF)",
            (value) => !value || SUPPORTED_IMAGE_FORMATS.includes(value.type)
          )
      ), */

    // video: type === "get" ? yup.string().required("Video is required") : "",
    video:
      type === "get"
        ? yup
            .array()
            .min(1, "At least one video is required")
            .max(3, "You can add up to 3 videos only")
            .of(yup.string().required("Video is required"))
        : "",
    document:
      type === "get"
        ? yup
            .array()
            .min(1, "At least one document is required")
            .of(yup.string().required("Document is required"))
        : "",
  });

  const formik = useFormik({
    initialValues: {
      bio: "",
      address: "",
      latestProfileImage: "",
      profileImages: [],
      document: [],
      video: [],
    },
    validationSchema: validationSchema,
    onSubmit: submit,
  });

  // Multiple Profile Images
  const deleteImages = async (index) => {
    setDeleteLoader(true);
    setDeleteIndex(index);
    const images = selectedImages.filter((_, i) => i !== index);

    formik.setFieldValue(
      "profileImages",
      formik.values.profileImages.filter((_, i) => i !== index)
    );
    setSelectedImages(images);
    setDeleteLoader(false);
  };

  /* const handleProfileChangeFile = async (e) => {
    const files = Array.from(e.target.files);
    await processProfileImages(files);
  }; */

  const handleProfileChangeFile = async (e) => {
    const files = Array.from(e.target.files);

    // Filter files to include only images
    const imageFiles = files.filter((file) =>
      SUPPORTED_IMAGE_FORMATS.includes(file.type)
    );

    if (imageFiles.length === 0) {
      // alert("Only image files are allowed (JPEG, PNG, GIF)");
      return;
    }

    // Check if any file exceeds 5MB
    const MAX_FILE_SIZE_MB = 5;
    const oversizedFiles = imageFiles.filter(
      (file) => file.size > MAX_FILE_SIZE_MB * 1024 * 1024
    );

    if (oversizedFiles.length > 0) {
      Toaster("error", `File must be under ${MAX_FILE_SIZE_MB}MB`);
      return;
    }

    // Process valid image files
    await processProfileImages(imageFiles);
    e.target.value = null;
  };

  /*  const processProfileImages = async (files) => {
    if (selectedImages.length + files.length > 5) {
      Toaster("error", "Maximum 5 images are allowed");
      return;
    }
    formik.setFieldValue("profileImages", files);

    const fileURLs = files.map((img) => URL.createObjectURL(img));
    setSelectedImages((prev) => [...prev, ...fileURLs]);
  }; */

  const processProfileImages = async (files) => {
    // Check for the total number of images (existing + new files)
    if (selectedImages.length + files.length > 5) {
      Toaster("error", "Maximum 5 images are allowed");
      return;
    }

    // Filter out duplicates
    const nonDuplicateFiles = files.filter((file) => {
      const isDuplicate = formik.values.profileImages.some(
        (existingFile) => existingFile.name === file.name
      );
      if (isDuplicate) {
        Toaster("error", `${file.name} has already been uploaded.`);
        return false; // Skip this file
      }
      return true; // Keep this file
    });

    // If no valid (non-duplicate) files were found, exit
    if (nonDuplicateFiles.length === 0) return;

    // Update Formik field with non-duplicate files
    formik.setFieldValue("profileImages", [
      ...formik.values.profileImages,
      ...nonDuplicateFiles,
    ]);

    // Create file URLs for the non-duplicate files and update selectedImages state
    const fileURLs = nonDuplicateFiles.map((img) => URL.createObjectURL(img));
    setSelectedImages((prev) => [...prev, ...fileURLs]);
  };

  // Latest Profile
  const handleLatestFileChnage = (e) => {
    const file = e.target.files[0];
    processLatestProfileImages(file);
    e.target.value = null;
  };

  const processLatestProfileImages = (file) => {
    console.log(
      "formik.values.latestProfileImage",
      formik.values.latestProfileImage
    );
    if (formik.values.latestProfileImage.name === file.name) {
      Toaster("error", `${file.name} has already been uploaded.`);
      return;
    }
    formik.setFieldValue("latestProfileImage", file);
    setSelectedLatestProfile(URL.createObjectURL(file));
  };

  const handleDrop = async (e, type) => {
    e.preventDefault();
    if (type === "profiles") {
      const files = Array.from(e.dataTransfer.files);
      await processProfileImages(files);
    } else {
      const files = e.dataTransfer.files[0];
      processLatestProfileImages(files);
    }
  };

  // Document
  const handleAddDocument = () => {
    handleClickOpenAddDocument();
    // document.body.style.overflow = "hidden";
  };

  const closeDocument = () => {
    setIsFileUpload(false);
    handleClickCloseAddDocument();
    handleScrollChange();
    setUploadProgress({});
    setUploadedFiles([]);
  };

  const processFiles = (files) => {
    setIsFileUpload(true);
    // const files = Array.from(e.target.files);
    const existingFileNames = new Set(uploadedFiles.map((file) => file.name));
    files.forEach((file) => {
      if (existingFileNames.has(file.name)) {
        Toaster("error", `${file.name} has already been uploaded.`);
        setIsFileUpload(false);
        return; // Skip adding the duplicate video
      }
      const reader = new FileReader();
      reader.onloadstart = () => {
        setUploadProgress((prev) => ({ ...prev, [file.name]: 0 }));
      };
      reader.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          setUploadProgress((prev) => ({ ...prev, [file.name]: progress }));
          if (progress === 100) {
            setTimeout(() => {
              setIsFileUpload(false);
            }, 1000);
          }
        }
      };
      reader.onloadend = () => {
        setUploadedFiles((prev) => [...prev, file]);
        setUploadProgress((prev) => ({ ...prev, [file.name]: 100 }));
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    // Combine existing uploaded files with new ones
    const totalFiles = [...uploadedFiles, ...files];

    // Validate the total number of files
    if (totalFiles.length > 10) {
      Toaster("error", `You can upload a maximum of 10 documents only.`);
      return;
    }
    processFiles(files);
  };

  const handleScrollChange = () => {
    document.body.style.overflow = "unset";
  };

  const handleDocumentSubmit = async () => {
    setDocumentLoader(true);
    setSelectedDocuments(uploadedFiles);
    formik.setFieldValue("document", uploadedFiles);
    handleClickCloseAddDocument();
    setDocumentLoader(false);
    setIsFileUpload(false);
  };

  const deleteDocument = async (index) => {
    setSelectedDocuments((prevDocs) => prevDocs.filter((_, i) => i !== index));
  };

  const handleOutsideClick = (event) => {
    if (documentRef.current && !documentRef.current.contains(event.target)) {
      closeDocument();
      document.body.style.overflow = "unset";
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleNextStep = () => {
    const { address, bio, latestProfileImage, profileImages } = formik.values;

    // if (address && bio && latestProfileImage && profileImages) {
    setCurrentStep((prev) => prev + 1);
    // } else {
    //   formik.setFieldError("bio", "Bio is required");
    //   formik.setFieldError("address", "Address is required");
    //   formik.setFieldError(
    //     "latestProfileImage",
    //     "Latest Profile Image is required"
    //   );
    //   formik.setFieldError("profileImages", "Profile image is required");
    // }
  };

  /*  const handleEditVideo = async (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = async function () {
        window.URL.revokeObjectURL(video.src);
        if (video.duration <= 60) {
          // formik.setFieldValue("video", file);
          formik.setFieldValue("video", [...formik.values.video, file]);
          const fileURLs = URL.createObjectURL(file);
          setSelectedVideo((prev) => [...prev, { file, url: fileURLs }]);
        } else {
          Toaster(
            "error",
            `${file.name} is longer than 60 seconds and cannot be uploaded.`
          );
        }
      };

      video.src = URL.createObjectURL(file);
    });

    e.target.value = "";
  }; */
  const handleEditVideo = async (e) => {
    const files = Array.from(e.target.files);
    if (formik.values.video?.length === 3) {
      Toaster("error", "You can add up to 3 videos only");
      return;
    }
    files.forEach((file) => {
      const video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = async function () {
        window.URL.revokeObjectURL(video.src);

        // Check if the video has already been added based on file name or URL
        const isDuplicate = formik.values.video.some(
          (existingFile) => existingFile.name === file.name
        );

        if (isDuplicate) {
          Toaster("error", `${file.name} has already been uploaded.`);
          return; // Skip adding the duplicate video
        }

        if (video.duration <= 60) {
          formik.setFieldValue("video", [...formik.values.video, file]);
          const fileURLs = URL.createObjectURL(file);
          setSelectedVideo((prev) => [...prev, { file, url: fileURLs }]);
        } else {
          Toaster(
            "error",
            `${file.name} is longer than 60 seconds and cannot be uploaded.`
          );
        }
      };

      video.src = URL.createObjectURL(file);
    });

    e.target.value = "";
  };

  /* const handleDeleteVideo = (videoUrl) => {
    const updatedVideos = selectedVideo.filter((video) => {
      return video.url !== videoUrl;
    });

    if (updatedVideos?.length === 0) {
      formik.setFieldValue("video", updatedVideos);
    }
    setSelectedVideo(updatedVideos);
  }; */

  const handleDeleteVideo = (videoUrl) => {
    // Filter out the video to be deleted based on the URL
    const updatedVideos = selectedVideo.filter(
      (video) => video.url !== videoUrl
    );

    // Update Formik's video field with the updated list of videos
    formik.setFieldValue(
      "video",
      updatedVideos.map((video) => video.file)
    );

    // Update the selectedVideo state
    setSelectedVideo(updatedVideos);
  };

  useEffect(() => {
    if (formik.submitCount > 0 && Object.keys(formik.errors).length > 0) {
      for (const [key, value] of Object.entries(formik.errors)) {
        if (key === "bio") {
          Toaster("error", "The bio field is missing. Please fill it out.");
        }
        if (key === "address") {
          Toaster(
            "error",
            "The address field is required. Please fill it out."
          );
        }
      }
    }
  }, [formik.submitCount, formik.errors]);

  return (
    <>
      <div className=" h-[100vh] grid grid-cols-12">
        <div className="lg:col-span-5 hidden lg:inline-block">
          <div className="h-screen w-full sticky top-0 left-0">
            <img src={banner} alt="" className="h-full w-full object-cover" />
          </div>
        </div>
        <div className="col-span-12 lg:col-span-7 flex px-4 h-full py-5  tab:ps-60 justify-center  max-h-screen overflow-y-auto">
          <div className="w-full max-w-2xl tab:mx-6 pb-3">
            <h4 className="text-lg sm:text-[26px] mb-3">Sign Up</h4>
            <p className="sm:text-xl text-14 mb-5">Welcome to Payyro</p>
            <div>
              <form
                className="max-w-3xl flex flex-col justify-between"
                onSubmit={formik.handleSubmit}
              >
                <div>
                  <div className="flex justify-between">
                    {steps?.map((step, i) => (
                      <div
                        key={i}
                        className={`step-item ${
                          currentStep === i + 1 && "active"
                        } ${i + 1 < currentStep && "complete"} `}
                      >
                        <div>
                          <div className="step">
                            {i + 1 < currentStep ? <TiTick size={24} /> : i + 1}
                          </div>
                          <p className="text-gray-500">{step}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div>
                    {currentStep === 1 && (
                      <SignUp2
                        formik={formik}
                        selectedImages={selectedImages}
                        deleteImages={deleteImages}
                        handleDrop={handleDrop}
                        handleChangeFile={handleProfileChangeFile}
                        handleLatestFileChnage={handleLatestFileChnage}
                        deleteLoader={deleteLoader}
                        deleteIndex={deleteIndex}
                        selectedLatestProfile={selectedLatestProfile}
                        isLoading={isLoading}
                        setSelectedLatestProfile={setSelectedLatestProfile}
                        setSelectedImages={setSelectedImages}
                      />
                    )}
                    {currentStep === 2 && (
                      <SignUp3
                        key={selectedVideo.length}
                        handleAddDocument={handleAddDocument}
                        selectedDocuments={selectedDocuments}
                        deleteDocument={deleteDocument}
                        formik={formik}
                        selectedVideo={selectedVideo}
                        handleEditVideo={handleEditVideo}
                        isLoading={isLoading}
                        handleDeleteVideo={handleDeleteVideo}
                      />
                    )}
                  </div>
                </div>
                <div className="w-full flex justify-between mt-8 pb-5">
                  {currentStep > 1 && (
                    <button
                      className="flex items-center gap-3 btn-big tab:!h-2 btn-primary"
                      variant="outline"
                      type="button"
                      onClick={() => setCurrentStep((prev) => prev - 1)}
                    >
                      Back
                    </button>
                  )}
                  <div className="w-full flex justify-end">
                    <div>
                      {currentStep === steps.length || type === "give" ? (
                        <>
                          {isLoading ? (
                            <button
                              className="flex items-center gap-3 btn-big !h-[37.16px] tab:!h-2 btn-primary w-[111.45px] tab:w-[123.45px] justify-center"
                              type="submit"
                            >
                              <img src={Loader} className="w-7 h-7" />
                            </button>
                          ) : (
                            <button
                              className="flex items-center gap-3 btn-big tab:!h-2 btn-primary"
                              type="submit"
                            >
                              Submit
                            </button>
                          )}
                        </>
                      ) : (
                        <>
                          {type === "get" && (
                            <div
                              className="flex items-center gap-3 btn-big tab:!h-2 btn-primary cursor-pointer"
                              onClick={handleNextStep}
                            >
                              Next
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        {openAddDocument && (
          <UploadDocument
            open={openAddDocument}
            handleClickClose={closeDocument}
            documentRef={documentRef}
            handleFileUpload={handleFileUpload}
            uploadProgress={uploadProgress}
            uploadedFiles={uploadedFiles}
            isFileUpload={isFileUpload}
            handleDocumentSubmit={handleDocumentSubmit}
            documentLoader={documentLoader}
            handleDrop={handleDrop}
          />
        )}
      </div>
    </>
  );
};

export default SetUpProfile;

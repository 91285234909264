import React, { useEffect, useState } from "react";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { RxSlash } from "react-icons/rx";
import { TiHome } from "react-icons/ti";
import { useNavigate, useParams } from "react-router-dom";
import HelpService from "../../service/help.service";
import Toaster from "../../common/toaster";
import moment from "moment";
import AvatarIcon from "../../common/Avatar";
import Loader from "../../../assets/loader.gif";
import ProgressBar from "../../common/ProgressBar";
import { scrollToTop } from "../../common/constatnts";
import { formatUploadTime, NumberWithCommas } from "../../common/Common";

const UserGiveHelpRequest = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleProfileNavigate = (id) => {
    navigate(`/user-give/help-profile/${id}`);
  };

  const handleHelpPayment = (id) => {
    navigate(`/user-give/help-payment/${id}`);
  };

  const getRequestDetails = async () => {
    try {
      const response = await HelpService.getRequestDetails(id);
      setData(response.data.data);
    } catch (error) {
      Toaster("error", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRequestDetails();
  }, [id]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="bg-cgreen">
      {isLoading ? (
        <div className="flex justify-center mt-3">
          <img src={Loader} alt="Loader" className="w-14 h-14" />
        </div>
      ) : (
        <div className="containers py-5">
          <div>
            <ol className="flex items-center gap-0.5">
              <li className="inline-flex items-center">
                <div onClick={() => navigate("/give-help/dashboard")}>
                  <TiHome className="h-[22px] w-[22px] text-primary/50 hover:text-primary duration-200" />
                </div>
              </li>
              <RxSlash className="text-primary/50" />
              <li>
                <div className="flex items-center">
                  <div
                    onClick={() => navigate("/give-help/help")}
                    className="sm:text-[1rem] text-[14px] font-bold text-primary/50 hover:text-primary duration-200 cursor-pointer"
                  >
                    Help Requests
                  </div>
                </div>
              </li>
              <RxSlash className="text-primary/50" />
              <li className="font-bold text-primary">
                <span className="sm:text-base text-sm">
                  {" "}
                  {data.customerName}’s Request
                </span>
              </li>
            </ol>
          </div>
          <div className="py-30 tab:pb-11 ">
            <div className="grid grid-cols-12 gap-[26px]">
              <div className="dashboard-card p-4 tab:p-26 col-span-12 lg:col-span-7">
                <h4 className="text-base tab:text-26 leading-none pb-2.5 tab:pb-5">
                  Help Request to Pay {data.category?.name} for{" "}
                  {data.customerName}
                </h4>
                <div className="flex justify-start items-center gap-4 pb-4">
                  <span className="py-1 px-2 text-cardbadge font-bold rounded-md text-xs bg-[#d3edff]">
                    <strong className="text-cardbadge">
                      {data.category?.name}
                    </strong>
                  </span>
                  <h6 className="text-sm line-clamp-1">
                    {data.vendor?.company}
                  </h6>
                </div>
                <div className="pb-4 b-b">
                  {data?.image && (
                    <div className="rounded-lg overflow-hidden">
                      <img
                        src={data?.image}
                        alt="User"
                        className="max-h-[350px] h-full w-full object-cover bg-center rounded-lg"
                      />
                    </div>
                  )}
                  <p className="pt-4 break-all">{data?.description}</p>
                </div>
                <div className="pt-4">
                  <div className="flex justify-between gap-3">
                    <div className="flex gap-3 avatar">
                      {data.userData?.latestProfileImage ? (
                        <img
                          src={data.userData?.latestProfileImage}
                          className="h-[60px] w-[60px] sm:h-20 sm:w-20 rounded-lg object-cover"
                        />
                      ) : (
                        <AvatarIcon
                          size={60}
                          className="rounded-lg"
                          name={data.userData?.name || data.userData?.firstName}
                        />
                      )}
                      <div>
                        <h5 className="font-normal sm:text-xl text-[1rem]">
                          {data.userData?.name || data.userData?.firstName}
                        </h5>
                        {data.userData?.isPayyroVerified && (
                          <div className="flex gap-1 h-fit items-center">
                            <RiVerifiedBadgeFill className="text-success w-3 h-3 sm:w-[14px] sm:h-[13.13px]" />
                            <h6 className="text-[10px] sm:text-xs">
                              Payyro Verified
                            </h6>
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <button
                        className="btn-small btn-outline-primary"
                        onClick={() => handleProfileNavigate(data._id)}
                      >
                        Visit Profile
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dashboard-card p-4 tab:p-26 col-span-12 lg:col-span-5">
                <div className="b-b pb-4 lg:pb-30">
                  <div className="flex justify-between items-center">
                    <h5 className="text-[1rem] sm:text-xl">
                      Balance: $
                      {NumberWithCommas(
                        data.requestedAmount - data.fundedAmount
                      )}
                    </h5>
                    <span className="text-xs sm:text-sm text-gray-500 font-bold">
                      Updated: {moment(data?.updatedAt).format("DD MMM YYYY")}
                    </span>
                  </div>
                  <div className="mb-4 mt-4">
                    <ProgressBar user={data} />
                  </div>
                  <div className="flex flex-col gap-2.5 lg:gap-4">
                    <p className="flex justify-between items-center text-sm sm:text-base">
                      Original Help Request{" "}
                      <strong>${NumberWithCommas(data.requestedAmount)}</strong>
                    </p>
                    <p className="flex justify-between items-center text-sm sm:text-base">
                      Payment Received{" "}
                      <strong>${NumberWithCommas(data.fundedAmount)}</strong>
                    </p>
                  </div>
                  <div>
                    <button
                      className="btn-big btn-primary mt-5 w-full"
                      onClick={() => handleHelpPayment(data._id)}
                    >
                      Help Now
                    </button>
                  </div>
                </div>
                <div className="flex flex-col gap-5 pt-4 lg:pt-30">
                  <h5 className="text-base lg:text-xl">
                    {data.doners?.length} People Donated
                  </h5>
                  <div className="max-h-[350px] overflow-y-auto user-donated">
                    {data.doners?.map((item) => (
                      <div className="flex justify-between items-center pb-2.5 tab:pb-5 last:pb-0">
                        <div className="flex gap-4 tab:gap-6 items-center avatar">
                          {item.userData?.latestProfileImage ? (
                            <img
                              src={item.userData?.latestProfileImage}
                              className="h-10 w-10 tab:w-12 tab:h-12 object-cover rounded-lg"
                            />
                          ) : (
                            <AvatarIcon
                              size={40}
                              tabSize={48}
                              className="rounded-lg"
                              name={
                                item.userData?.firstName || item.userData?.name
                              }
                            />
                          )}
                          <h5 className="text-base sm:text-xl font-normal">
                            {item.userData?.firstName || item.userData?.name}
                          </h5>
                          <strong className="text-primary/60 text-xs tab:text-sm">
                            {formatUploadTime(item?.createdAt)}
                          </strong>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserGiveHelpRequest;

import React, { useEffect, useState } from "react";
import { BsDownload } from "react-icons/bs";
import { scrollToTop } from "../common/constatnts";
import Papa from "papaparse";
import Toaster from "../common/toaster";
import VendorService from "../service/vendor.service";
import { FaFileCsv } from "react-icons/fa6";
import { formatFileSize } from "../common/Common";
import { IoMdCloseCircle } from "react-icons/io";
import Loader from "../../assets/loader.gif";

const VendorUploadBulkRequest = () => {
  const [csvData, setCsvData] = useState([]);
  const [fileDetails, setFileDetails] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const csvUploadFn = (event) => {
    const file = event.target.files[0];
    processCSV(file);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files[0];
    processCSV(files);
  };

  const processCSV = (file) => {
    setFileDetails(file);
    const fileType = file.name.split(".").pop().toLowerCase();
    if (fileType !== "csv") {
      Toaster("error", "Please upload a CSV file.");
      return;
    }

    Papa.parse(file, {
      complete: (result) => {
        const filteredData = result.data.filter((row) => {
          return Object.values(row).every((value) => value !== "");
        });

        setCsvData(JSON.stringify(filteredData, null, 2));
      },
      header: true,
    });
  };

  const uploadCSV = async () => {
    try {
      if (csvData == "") {
        Toaster("error", "Please upload CSV file");
        return;
      }

      setIsLoading(true);
      const body = { helpRequests: JSON.parse(csvData) };

      const reponse = await VendorService.createBulkHelpRequest(body);
      setCsvData([]);
      setFileDetails("");
      Toaster("success", reponse.data.message);
    } catch (error) {
      Toaster("error", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const deleteDocument = () => {
    setFileDetails("");
    setCsvData([]);
  };

  return (
    <div className="dashboard-part flex gap-9 min-h-[calc(100vh-200px)]">
      <div>
        <div className="dashboard-card rounded-10 w-full tab:max-w-[758px] tab:min-w-[758px]">
          <h6 className="tab:text-base text-sm tab:py-5 tab:px-26 py-4 px-4 b-b border-primary/20">
            Create Help Request and Submit to Customer for Publication{" "}
          </h6>
          <div className="flex flex-col gap-[26px] tab:px-26 tab:pt-26 px-4 pt-4">
            <div className="flex flex-col gap-2.5">
              <h6 className="text-sm tab:text-base">
                Do you want to upload in bulk?
              </h6>
              <p className="text-xs tab:text-sm">
                Please prepare a CSV file with the invoice details to create
                multiple Help Requests to be submitted to your Customers for
                publication.
              </p>
              <p className="text-xs tab:text-sm">
                Here is a sample file for you to guide.
              </p>
            </div>
            <div className="relative">
              <label
                className="mb-0 z-0 border-2 border-dashed border-primary/20 rounded-md w-full h-20 tab:h-[50px] flex items-center justify-center gap-1.5 cursor-pointer"
                htmlFor="bill-upload"
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => handleDrop(e)}
              >
                <BsDownload className="w-[18px] h-[18px]" />
                <h6 className="text-sm">
                  Upload a .CSV file with multiple Help Requests
                </h6>
              </label>
              <input
                id="bill-upload"
                type="file"
                className="hidden"
                accept=".csv,.CSV"
                onChange={csvUploadFn}
              />
            </div>
            {fileDetails != "" && (
              <div className="flex justify-between items-start border border-primary/20 w-full rounded-lg p-2.5">
                <div className="flex justify-start items-start gap-2.5">
                  <div className={`text-gray-500 text-2xl`}>
                    <FaFileCsv />
                  </div>
                  <div>
                    <h6
                      className="text-base break-all line-clamp-1"
                      title={fileDetails.name}
                    >
                      {fileDetails.name}
                    </h6>
                    <p className="text-sm">
                      {formatFileSize(fileDetails.size)}
                    </p>
                  </div>
                </div>

                <div className="flex flex-col items-end">
                  <IoMdCloseCircle
                    className="h-4 w-4 text-primary/40 cursor-pointer"
                    onClick={() => deleteDocument()}
                  />
                </div>
              </div>
            )}
            <div className="flex flex-col gap-2.5 b-b border-primary/20 tab:pb-26 pb-4">
              <h6 className="text-sm tab:text-[13px]">Note:</h6>
              <p className="text-sm tab:text-[13px]">
                Uploading bulk Help Requests will NOT automatically publish them
                for public viewing.
              </p>
              <p className="text-sm tab:text-[13px]">
                During the upload process, you can specify which Help Requests
                should be submitted to your customers for publication.
              </p>
            </div>
          </div>
          <div className="text-end tab:py-5 tab:px-26 px-4 py-4">
            {/* <button
              type="submit"
              className="btn-big btn-primary"
              onClick={uploadCSV}
            >
              Upload CSV
            </button> */}
            {isLoading ? (
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="btn-big btn-primary tab:!w-[156.13px] tab:!h-[49.16px] flex items-center justify-center !w-[144.13px] !h-[37.16px]"
                >
                  <img src={Loader} alt="Loader" className="w-7 h-7" />
                </button>
              </div>
            ) : (
              <button
                type="submit"
                className="btn-big btn-primary"
                onClick={uploadCSV}
              >
                Upload CSV
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorUploadBulkRequest;

import React, { useState } from "react";

const UseDialogState = () => {
  const [open, setOpen] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openAddCard, setOpenAddCard] = useState(false);
  const [openAddDocument, setOpenAddDocument] = useState(false);
  const [bio, setBio] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleClickModelOpen = () => {
    setIsModalOpen(true);
  };

  const handleClickModelClose = () => {
    setIsModalOpen(false);
  };

  const handleClickOpenChangePassword = () => {
    setOpenChangePassword(true);
  };

  const handleClickCloseChangePassword = () => {
    setOpenChangePassword(false);
  };

  const handleClickOpenAddCard = () => {
    setOpenAddCard(true);
  };

  const handleClickCloseAddCard = () => {
    setOpenAddCard(false);
  };
  const handleClickOpenAddDocument = () => {
    setOpenAddDocument(true);
  };

  const handleClickCloseAddDocument = () => {
    setOpenAddDocument(false);
  };

  const handleOpenBio = () => {
    setBio(true);
  };
  const handleCloseBio = () => {
    setBio(false);
  };

  return {
    open,
    handleClickOpen,
    handleClickClose,
    isModalOpen,
    handleClickModelOpen,
    handleClickModelClose,
    openChangePassword,
    handleClickCloseChangePassword,
    handleClickOpenChangePassword,
    openAddCard,
    handleClickOpenAddCard,
    handleClickCloseAddCard,
    openAddDocument,
    handleClickCloseAddDocument,
    handleClickOpenAddDocument,
    bio,
    handleOpenBio,
    handleCloseBio,
  };
};

export default UseDialogState;

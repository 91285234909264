import moment from "moment";
import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import { MdAttachFile } from "react-icons/md";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import AvatarIcon from "../../common/Avatar";
import ProgressBar from "../../common/ProgressBar";
import jsPDF from "jspdf";
import "jspdf-autotable";
import PaymentReceipt from "../../common/PaymentReceipt";
import html2pdf from "html2pdf.js";
import ReactDOM from "react-dom";

const HelpByMeCard = ({ open, handleClickClose, helpRef, helpSingleData }) => {
  const type = JSON.parse(helpSingleData.response).data.object.charges.data[0]
    .payment_method_details.type;

  const cardLast4 =
    type === "card"
      ? JSON.parse(helpSingleData?.response)?.data?.object?.charges?.data[0]
          ?.payment_method_details?.card?.last4
      : JSON.parse(helpSingleData?.response)?.data?.object?.charges?.data[0]
          ?.payment_method_details?.us_bank_account?.last4;

  const paymentDate = moment
    .unix(
      JSON.parse(helpSingleData?.response)?.data?.object?.charges?.data[0]
        ?.created
    )
    .format("DD MMM, YYYY");

  const paymentTime = moment
    .unix(
      JSON.parse(helpSingleData?.response)?.data?.object?.charges?.data[0]
        ?.created
    )
    .format("hh:mm A");

  const receiptUrl = JSON.parse(helpSingleData?.response)?.data?.object?.charges
    ?.data[0]?.receipt_url;

  const handleDownload = () => {
    const receiptContainer = document.createElement("div");
    receiptContainer.classList.add("receipt-container");

    const transactionSlip = <PaymentReceipt rowData={helpSingleData} />;
    const paymentSlip = document.createElement("div");
    ReactDOM.render(transactionSlip, paymentSlip);
    receiptContainer.appendChild(paymentSlip);

    document.body.appendChild(receiptContainer);

    const options = {
      margin: -5,
      filename: `${
        helpSingleData.user?.name || helpSingleData.user?.firstName || helpSingleData.user?.email.split("@")[0]
      }_Receipt.pdf`,
      image: { type: "jpeg", quality: 1.0 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "px",
        format: [900, 700],
        orientation: "portrait",
      },
    };

    html2pdf().from(receiptContainer).set(options).save();

    document.body.removeChild(receiptContainer);
  };

  return (
    <div>
      {open && (
        <div className="fixed inset-0 bg-black/50 z-[999] overflow-y-auto">
          <div
            className="flex items-center justify-center min-h-screen px-4"
            // onClick={() => setOpen(false)}
          >
            <div
              className="dashboard-card px-4 py-3.5 tab:py-[18px] max-w-[550px] w-full min-w-[350px]"
              ref={helpRef}
            >
              <div className="flex flex-col gap-3 tab:gap-3.5">
                {/* {statusdata.map((data) => (
                <> */}
                <div className="flex justify-between items-center b-b border-primary/20 pb-3 tab:pb-3.5">
                  <h6 className="text-sm tab:text-base">Help by Me</h6>
                  <div className="cursor-pointer" onClick={handleClickClose}>
                    <IoCloseOutline className="h-4 w-4" />
                  </div>
                </div>
                <div className="flex justify-between items-center b-b border-primary/20 pb-3 tab:pb-3.5">
                  <h6 className="text-xs tab:text-sm text-primary/60">
                    {`${moment(helpSingleData.createdAt).format(
                      "DD MMM, YYYY"
                    )} at ${moment(helpSingleData.createdAt).format(
                      "hh:mm A"
                    )}`}
                  </h6>
                  <h6 className="text-xs tab:text-sm text-primary/60">
                    {helpSingleData.invoiceId}
                  </h6>
                </div>
                <div className="flex flex-col gap-4 b-b border-primary/20 pb-3 tab:pb-3.5">
                  <h2>${helpSingleData.amount}</h2>
                  <div className="flex flex-col gap-2">
                    <p className="text-xs flex justify-between items-center">
                      Payment done using:
                      <strong>
                        {type === "card" ? "Card" : "Bank Account Number"}{" "}
                        ending with {cardLast4}
                      </strong>
                    </p>
                    <p className="text-xs flex justify-between items-center">
                      Payment made on:
                      <strong>
                        {paymentDate} at {paymentTime}
                      </strong>
                    </p>
                  </div>
                </div>
                <div className="flex justify-between items-start">
                  <div className="flex gap-3">
                    <div className="tab:h-20 tab:w-20 h-[75px] w-[75px] rounded-lg overflow-hidden avatar">
                      {helpSingleData.user?.latestProfileImage ? (
                        <img
                          className="object-cover bg-cover bg-center w-full h-full"
                          src={helpSingleData.user?.latestProfileImage}
                        />
                      ) : (
                        <AvatarIcon
                          size={75}
                          name={
                            helpSingleData.user?.name ||
                            helpSingleData.user?.firstName
                          }
                          className="rounded-lg"
                        />
                      )}
                    </div>
                    <div>
                      <div className="tab:pb-[10px] pb-0 flex items-center gap-2.5">
                        <span className="tab:py-1 py-[3px] tab:px-2 px-1.5 bg-blue/10 rounded">
                          <strong className="tab:text-xs text-[10px] text-blue">
                            {helpSingleData.category?.name}
                          </strong>
                        </span>
                        <h6 className="text-[10px] tab:text-sm">
                          {helpSingleData.vendor?.company}
                        </h6>
                      </div>
                      <h5 className="tab:text-[20px] text-base font-normal">
                        {helpSingleData.user?.name ||
                          helpSingleData.user?.firstName}
                      </h5>
                      <span className="text-primary/60 font-bold leading-none text-10">
                        Updated:{" "}
                        {`${moment(helpSingleData.updatedAt).format(
                          "DD MMM, YYYY"
                        )}`}
                      </span>
                    </div>
                  </div>
                  {helpSingleData.user?.isPayyroVerified && (
                    <div className="flex justify-start items-center gap-1">
                      <RiVerifiedBadgeFill className="text-success text-xs tab:text-sm" />
                      <h6 className="text-xs tab:text-sm">Payyro Verified</h6>
                    </div>
                  )}
                </div>
                <div className="b-b border-primary/20">
                  <h6 className="text-sm font-normal pb-2.5">Current Status</h6>
                  {/* <div
                    className={`bg-success h-1 rounded-full mb-2.5 dark:bg-blue-500`}
                  ></div> */}
                  <div className="mb-2">
                    <ProgressBar user={helpSingleData} />
                  </div>
                  <div className="flex justify-between items-center pb-3 tab:pb-3.5">
                    <h6 className="text-xs tab:text-sm">
                      ${helpSingleData.fundedAmount?.toFixed(2)} funded
                    </h6>
                    <h6 className="text-xs tab:text-sm">
                      ${helpSingleData.requestedAmount?.toFixed(2)} requested
                    </h6>
                  </div>
                </div>
                <div className="text-end">
                  <button
                    className="btn-default btn-primary w-[176px] h-[37px] text-sm"
                    onClick={handleDownload}
                  >
                    Download Invoice
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="hidden">
        <PaymentReceipt rowData={helpSingleData} />
      </div>
    </div>
  );
};

export default HelpByMeCard;

"use client";
import React, { useEffect, useState } from "react";
import "firebase/messaging";
import firebaseCloudMessaging from "./firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function PushNotificationLayout({ children }) {
  useEffect(() => {
    // if (typeof window !== "undefined") {
    //   setToken();
    //   if ("serviceWorker" in navigator) {
    //     navigator.serviceWorker.addEventListener("message", (event) => {
    //       toast.info(
    //         <div className="grid grid-cols-12">
    //           <div className="col-span-4 flex justify-center items-center">
    //             <img
    //               src={event.data.notification?.image}
    //               alt="image"
    //               className="rounded-full mr-2 object-cover h-[60px]"
    //               width={60}
    //               height={60}
    //             />
    //           </div>
    //           <div className="col-span-8 flex items-center">
    //             <h4 className="text-[18px]">
    //               {event.data.notification?.title}
    //             </h4>
    //           </div>
    //         </div>,
    //         {
    //           closeOnClick: false,
    //         }
    //       );
    //     });
    //   }
    //   async function setToken() {
    //     try {
    //       const token = await firebaseCloudMessaging?.init();
    //       if (token) {
    //         // getMessage();
    //       }
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   }
    // }
  }, []);

  return (
    <>
      <ToastContainer theme="light" autoClose={1500} />
      {children}
    </>
  );
}

export default PushNotificationLayout;

import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDBsOpu8KmIkuwN0dJEJerO5a_umhvdaPg",
  authDomain: "payyro-a446b.firebaseapp.com",
  projectId: "payyro-a446b",
  storageBucket: "payyro-a446b.appspot.com",
  messagingSenderId: "761826963052",
  appId: "1:761826963052:web:6d59df15026b07bb78f249",
  measurementId: "G-5HVJ4MT43W",
};

const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;

import { useFormik } from "formik";
import React, { useEffect } from "react";
import { IoLocation } from "react-icons/io5";
import Input from "../../common/Input";
import { FaCamera } from "react-icons/fa";
import * as yup from "yup";
import { scrollToTop } from "../../common/constatnts";

const EditVendorProfile = () => {
  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().required("Email is required"),
    companyname: yup.string().required("Company Name is required"),
    address: yup.string().required("Address is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      companyname: "",
      address: "",
    },
    validationSchema: validationSchema,
  });

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div>
      <div className="fixed inset-0 z-[999] overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen px-4">
          <div className="dashboard-card overflow-hidden max-w-[652px] w-full min-w-[350px]">
            <div className="b-b border-primary/20 tab:px-26 px-4 py-4 tab:py-5">
              <h5 className="tab:text-xl text-sm">Edit Profile</h5>
            </div>
            <div className="tab:px-26 px-4 pt-4 tab:pt-26">
              <div className="block tab:flex tab:text-start text-center justify-center tab:justify-start items-center  tab:gap-9 b-b border-primary/20 pb-5 tab:pb-26">
                <div className="relative tab:h-[120px] tab:w-[120px] h-20 w-20 mx-auto tab:mx-0 mb-5 tab:mb-0">
                  <img
                    src="https://images.pexels.com/photos/1043471/pexels-photo-1043471.jpeg?auto=compress&cs=tinysrgb&w=600"
                    className="h-full w-full rounded-full object-cover"
                  />
                  <label
                    htmlFor="profile-upload"
                    className="absolute  bottom-2 right-0  h-[22px] w-[22px] tab:h-9 tab:w-9 rounded-full flex justify-center items-center bg-primary cursor-pointer"
                  >
                    <FaCamera className="text-white text-lg" />
                    <input id="profile-upload" type="file" className="hidden" />
                  </label>
                </div>
                <div>
                  <h6 className="tab:text-lg text-base">Profile Photo</h6>
                  <p className="text-sm">Browse or Drag image to upload</p>
                </div>
              </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="flex flex-col gap-4 p-4 tab:p-26">
                <h6 className="tab:text-base text-sm">Personal Details</h6>
                <div className="grid grid-cols-1 tab:grid-cols-2 flex-col gap-4">
                  <div>
                    <label>Name*</label>
                    <Input
                      name="name"
                      type="text"
                      placeholder=""
                      formik={formik}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="valierror">{formik.errors.name}</div>
                    ) : null}
                  </div>
                  <div>
                    <label>Email*</label>
                    <Input
                      name="email"
                      type="email"
                      placeholder=""
                      formik={formik}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="valierror">{formik.errors.email}</div>
                    ) : null}
                  </div>
                  <div>
                    <label>Company Name*</label>
                    <Input
                      name="companyname"
                      type="text"
                      placeholder=""
                      formik={formik}
                    />
                    {formik.touched.companyname && formik.errors.companyname ? (
                      <div className="valierror">
                        {formik.errors.companyname}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <label>Address*</label>
                    <div className="relative">
                      <Input
                        formik={formik}
                        name="address"
                        type="text"
                        placeholder=""
                      />
                      <div className="absolute top-1/2 right-3 -translate-y-1/2">
                        <IoLocation className="text-primary/50" />
                      </div>
                    </div>
                    {formik.touched.address && formik.errors.address ? (
                      <div className="valierror">{formik.errors.address}</div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className=" b-b border-primary/20 mb-26"></div>
              <div className="text-end tab:py-5 tab:px-26 py-4 px-4">
                <button
                  type="submit"
                  className="btn btn-primary h-[37px] w-[147px] tab:h-[49px] tab:w-[167px]"
                >
                  Update Profile
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditVendorProfile;

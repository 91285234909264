import React, { useEffect, useState } from "react";
import { LuUpload } from "react-icons/lu";
import SlashInput from "../../common/SlashInput";
import { IoLocation } from "react-icons/io5";
import Textarea from "../../common/textarea";
import { AiFillCamera, AiFillDelete } from "react-icons/ai";
import Loader from "../../../assets/loader.gif";
import { MdOutlineAdd } from "react-icons/md";
import Profile from "../../../assets/profile.jpg";
import { useNavigate, useParams } from "react-router-dom";

const SignUp2 = ({
  formik,
  selectedImages,
  deleteImages,
  handleDrop,
  handleChangeFile,
  deleteLoader,
  deleteIndex,
  handleLatestFileChnage,
  selectedLatestProfile,
  setSelectedLatestProfile,
  setSelectedImages,
}) => {
  const navigate = useNavigate();
  /*  const handleClick = () => {
    const previousValue = {
      address: formik.values.address || "", // Ensure address is not undefined
      bio: formik.values.bio || "", // Ensure bio is not undefined
    };
    localStorage.setItem("prevData", JSON.stringify(previousValue)); // Store data in localStorage
    navigate("/effective-bio");
  }; */

  const handleClick = async () => {
    const convertFileToBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });

    const imagesBase64 = await Promise.all(
      selectedImages.map(async (imageURL) => {
        const blob = await fetch(imageURL).then((r) => r.blob());
        return convertFileToBase64(blob);
      })
    );

    const previousValue = {
      address: formik.values.address || "",
      bio: formik.values.bio || "",
      selectedLatestProfile: formik.values.latestProfileImage
        ? await convertFileToBase64(
            formik.values.latestProfileImage || selectedLatestProfile
          )
        : null,
      profileImages: imagesBase64, // Save profile images
    };

    localStorage.setItem("prevData", JSON.stringify(previousValue));
    navigate("/effective-bio");
  };

  const { type } = useParams();

  /*  useEffect(() => {
    const prevData = localStorage.getItem("prevData");
    if (prevData) {
      const parsedData = JSON.parse(prevData);
      formik.setValues({
        ...formik.values,
        bio: parsedData?.bio || "",
        address: parsedData?.address || "",
      });
      localStorage.removeItem("prevData");
    }
  }, []); */

  useEffect(() => {
    const prevData = localStorage.getItem("prevData");
    if (prevData) {
      const parsedData = JSON.parse(prevData);

      // Restore formik values
      formik.setValues({
        ...formik.values,
        bio: parsedData?.bio || "",
        address: parsedData?.address || "",
      });

      // Restore latest profile photo
      if (parsedData?.selectedLatestProfile) {
        const byteString = atob(parsedData.selectedLatestProfile.split(",")[1]);
        const mimeString = parsedData.selectedLatestProfile
          .split(",")[0]
          .split(":")[1]
          .split(";")[0];
        const byteNumbers = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
          byteNumbers[i] = byteString.charCodeAt(i);
        }
        const fileBlob = new Blob([byteNumbers], { type: mimeString });
        setSelectedLatestProfile(URL.createObjectURL(fileBlob));
      }

      // Restore profile images
      if (parsedData?.profileImages?.length) {
        const imageURLs = parsedData.profileImages.map((base64String) => {
          const byteString = atob(base64String.split(",")[1]);
          const mimeString = base64String
            .split(",")[0]
            .split(":")[1]
            .split(";")[0];
          const byteNumbers = new Uint8Array(byteString.length);
          for (let i = 0; i < byteString.length; i++) {
            byteNumbers[i] = byteString.charCodeAt(i);
          }
          const fileBlob = new Blob([byteNumbers], { type: mimeString });
          return URL.createObjectURL(fileBlob);
        });
        setSelectedImages(imageURLs);
      }

      localStorage.removeItem("prevData");
    }
  }, []);

  return (
    <div className="my-4 flex flex-col gap-3">
      <div className="mb-2">
        <div
          className="block tab:flex tab:text-start text-center justify-center tab:justify-start items-center tab:gap-9"
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => handleDrop(e, "latestProfile")}
        >
          <div className="relative tab:h-[100px] tab:w-[100px] h-20 w-20 mx-auto tab:mx-0 mb-5 tab:mb-0">
            <img
              src={selectedLatestProfile ? selectedLatestProfile : Profile}
              alt="Profile"
              className="h-full w-full rounded-full object-cover"
            />
            <label
              htmlFor="profile-upload"
              className="absolute bottom-1 -right-1  h-[22px] w-[22px] tab:h-9 tab:w-9 rounded-full flex justify-center items-center bg-primary cursor-pointer"
            >
              <AiFillCamera className="text-white text-xl" />
              <input
                id="profile-upload"
                onChange={handleLatestFileChnage}
                type="file"
                className="hidden"
                accept="image/*"
              />
            </label>
          </div>
          <div>
            <h6 className="tab:text-lg text-base ">Profile Photo</h6>
            <p className="text-sm">Browse or Drag image to upload</p>
          </div>
        </div>
        {formik.errors.latestProfileImage ? (
          <div className="valierror mt-2">
            {formik.errors.latestProfileImage}
          </div>
        ) : null}
      </div>

      <div>
        <label>Address*</label>
        <div className="relative">
          <SlashInput
            formik={formik}
            name="address"
            type="text"
            placeholder=""
          />
          <div className="absolute top-1/2 right-3 -translate-y-1/2">
            <IoLocation className="text-primary/50" />
          </div>
        </div>
        {formik.errors.address ? (
          <div className="valierror">{formik.errors.address}</div>
        ) : null}
      </div>

      {type !== "give" && (
        <div>
          <div className="flex justify-between pb-4 tab:pb-2">
            <label className="flex items-end">About Me*</label>
            <button
              className="btn-default btn-outline-primary flex items-center gap-1 text-sm px-5 tab:px-26 leading-none group"
              onClick={handleClick}
            >
              Tips for an Effective Bio
            </button>
          </div>
          <div className="relative">
            <Textarea name="bio" type="text" placeholder="" formik={formik} />
          </div>
          {formik.errors.bio ? (
            <div className="valierror">{formik.errors.bio}</div>
          ) : null}
        </div>
      )}

      <div className="flex justify-between gap-2 items-center tab:items-start pb-4 tab:pb-3">
        <div>
          {type === "give" && (
            <div className="flex gap-1 justify-center items-center">
              <>
                <h6 className="text-sm tab:text-lg">Profile Images</h6>{" "}
                <span>{`(Optional)`}</span>
              </>
            </div>
          )}
          {type !== "give" && (
            <h6 className="text-sm tab:text-lg">Profile Images</h6>
          )}
          <p className="text-xs tab:text-13">(Max. 5 images allowed)</p>
        </div>
        <label
          htmlFor="upload"
          className="btn-default btn-outline-primary flex items-center gap-1 text-sm px-5 tab:px-26 py-2.5 leading-none group cursor-pointer"
          type="button"
        >
          <MdOutlineAdd className="text-primary text-base group-hover:text-white duration-200" />
          Add Image
        </label>
      </div>

      <div className="flex items-center gap-4">
        {selectedImages &&
          selectedImages?.map((img, index) => {
            return (
              <div className="w-[120px] h-[120px] relative" key={index}>
                <img
                  src={img}
                  alt=""
                  className="rounded-10 w-full h-full object-cover"
                />
                <div
                  className="w-8 h-8 bg-white/80 absolute top-2 right-2 rounded-10 cursor-pointer"
                  onClick={() => deleteImages(index)}
                >
                  {deleteLoader ? (
                    <>
                      {deleteIndex === index ? (
                        <img src={Loader} alt="Loader" />
                      ) : (
                        <AiFillDelete className="text-red-500 text-17 content-center" />
                      )}
                    </>
                  ) : (
                    <AiFillDelete className="text-red-500 text-17 content-center" />
                  )}
                </div>
              </div>
            );
          })}

        {selectedImages.length < 5 && (
          <div
            className="bg-primary/10 w-[120px] h-[120px] rounded-10 flex items-center p-1"
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => handleDrop(e, "profiles")}
          >
            <div className="mx-auto">
              <label
                htmlFor="upload"
                className="w-full flex flex-col justify-center items-center"
              >
                <div className="m-4 mt-0 w-8 h-8 bg-white/60 rounded-lg flex justify-center items-center">
                  <LuUpload className="text-17" />
                </div>
                <div className="text-center">
                  Browse or Drag image to upload
                </div>
              </label>
            </div>
          </div>
        )}

        <input
          type="file"
          multiple
          onChange={(e) => handleChangeFile(e)}
          hidden
          id="upload"
          accept="image/*"
        />
      </div>
      {formik.errors.profileImages ? (
        <div className="valierror mt-2">{formik.errors.profileImages}</div>
      ) : null}
    </div>
  );
};

export default SignUp2;

import React from "react";
import { Navigate, useRoutes } from "react-router";
import { lazy } from "react";
import VendorHelpRequest from "../../app/vendor/VendorHelpRequest";
import VendorUploadBulkRequest from "../../app/vendor/VendorUploadBulkRequest";
import VendorProfile from "../../app/vendor/vendorProfile/VendorProfile";
import EditVendorProfile from "../../app/vendor/vendorProfile/EditVendorProfile";
import GiveHelpDashboard from "../../app/user/givehelp/GiveHelpDashboard";
import UserGiveHelp from "../../app/user/givehelp/UserGiveHelp";
import UserGiveHelpProfile from "../../app/user/givehelp/UserGiveHelpProfile";
import UserGiveHelpRequest from "../../app/user/givehelp/UserGiveHelpRequest";
import UserGiveHelpPayment from "../../app/user/givehelp/UserGiveHelpPayment";
import GiveHelpUserProfile from "../../app/user/givehelp/GiveHelpUserProfile";
import NotFound from "../../app/common/NotFound";
import EditBothUserProfile from "../../app/common/EditBothUserProfile";
import UserGetHelpProfile from "../../app/user/gethelp/UserGetHelpProfile";
import Notifications from "../../app/notifications/Notifications";
import EffectiveBio from "../../app/user/gethelp/EffectiveBio";
import Home from "../../app/public/Home";
import PublicLayout from "../../app/PublicLayout";

const SuspenseLoader = (Component) => (props) =>
  (
    <React.Suspense fallback={<withLoader />}>
      <Component {...props} />
    </React.Suspense>
  );

const Dashboard = SuspenseLoader(
  lazy(() => import("../../app/user/gethelp/Dashboard"))
);
const MyHelpRequest = SuspenseLoader(
  lazy(() => import("../../app/user/gethelp/MyHelpRequest"))
);
const PrivateLayout = SuspenseLoader(
  lazy(() => import("../../app/PrivateLayout"))
);
const UserGetHelp = SuspenseLoader(
  lazy(() => import("../../app/user/gethelp/UserGetHelp"))
);
const UserFaqs = SuspenseLoader(
  lazy(() => import("../../app/user/userFaqs/UserFaqs"))
);
const VendorDashboard = SuspenseLoader(
  lazy(() => import("../../app/vendor/VendorDashboard"))
);
const VenderCreateRequest = SuspenseLoader(
  lazy(() => import("../../app/user/gethelp/VenderCreateRequest"))
);

function RedirectionWrapper({ to }) {
  const queryString = window.location.search;
  if (queryString) {
    return <Navigate to={`${to}${queryString}`} />;
  }
  return <Navigate to={to} />;
}

const routes = (isLoggedIn, role) => [
  {
    path: "/",
    element: isLoggedIn ? <PrivateLayout /> : <RedirectionWrapper to="/" />,
    children: [
      {
        element: <RedirectionWrapper to="/get-help/dashboard" />,
        index: true,
      },

      // Get Help Routes
      {
        path: "/get-help/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/get-help/user-get-help",
        element: <UserGetHelp />,
      },
      {
        path: "/get-help/my-help-request",
        element: <MyHelpRequest />,
      },
      {
        path: "/get-help/user-profile",
        element: <UserGetHelpProfile />,
      },
      {
        path: "/user-faqs",
        element: <UserFaqs />,
      },
      {
        path: "/get-help/create-request",
        element: <VenderCreateRequest />,
      },

      // Vendor Routes
      {
        path: "/vendor/dashboard",
        element: <VendorDashboard />,
      },
      {
        path: "/vendor/help-request",
        element: <VendorHelpRequest />,
      },
      {
        path: "/vendor/upload-bulk-request",
        element: <VendorUploadBulkRequest />,
      },
      {
        path: "/vendor/profile",
        element: <VendorProfile />,
      },
      {
        path: "/vendor/edit-profile",
        element: <EditVendorProfile />,
      },

      // Give Help Routes
      {
        path: "/give-help/dashboard",
        element: <GiveHelpDashboard />,
      },
      {
        path: "/give-help/help",
        element: <UserGiveHelp />,
      },
      {
        path: "/user-give/help-profile/:id",
        element: <UserGiveHelpProfile />,
      },
      {
        path: "/user-give/help-request/:id",
        element: <UserGiveHelpRequest />,
      },
      {
        path: "/user-give/help-payment/:id",
        element: <UserGiveHelpPayment />,
      },
      {
        path: "/give-help/user-profile",
        element: <GiveHelpUserProfile />,
      },
      {
        path: "/edit-profile",
        element: <EditBothUserProfile />,
      },

      // Notification
      {
        path: "/notifications",
        element: <Notifications />,
      },
    ],
  },
  {
    path: "/",
    element: isLoggedIn ? <PrivateLayout /> : <RedirectionWrapper to="/" />,
  },
  {
    path: "/home",
    element: <PublicLayout />,
    children: [
      {
        element: <RedirectionWrapper to="/home" />,
        index: true,
        element: <Home />,
      },
    ],
  },
  // { path: "*", element: <NotFound /> },
];

export default function PrivateRoutes({ isLoggedIn, role }) {
  return useRoutes(routes(isLoggedIn, role));
}

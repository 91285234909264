import React from "react";

const Textarea = ({ formik, name, type, placeholder }) => {
  return (
    <textarea
      name={name}
      type={type}
      value={formik.values[name]}
      placeholder={placeholder}
      className="h-[70px] tab:h-[88px] textarea"
      onChange={formik.handleChange}
    />
  );
};

export default Textarea;

import React, { useContext, useEffect, useState } from "react";
import { FaHandHoldingHeart } from "react-icons/fa";
import UserService from "../../service/user.service";
import moment from "moment";
import { useSelector } from "react-redux";
import Toaster from "../../common/toaster";
import Loader from "../../../assets/loader.gif";
import { scrollToTop } from "../../common/constatnts";
import { NumberWithCommas } from "../../common/Common";

const GiveHelpDashboard = () => {
  const [data, setData] = useState([]);
  const { token } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);

  const getHelpDashboard = async () => {
    try {
      const reaponse = await UserService.giveHelpDashboard(token);
      setData(reaponse.data.data);
    } catch (error) {
      Toaster("error", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getHelpDashboard();
    scrollToTop();
  }, []);

  return (
    <div className="dashboard-part flex lg:gap-9 min-h-[calc(100vh-200px)]">
      {/* Dashboard Total Uer Card */}

      {isLoading ? (
        <div className="flex justify-center w-full items-center">
          <img src={Loader} alt="Loader" className="w-14 h-14" />
        </div>
      ) : (
        <div className="w-full">
          <div className="grid grid-cols-2 tab:grid-cols-4 columns-4 gap-4">
            <div className="dashboard-card py-5 px-4 flex flex-col gap-3 lg:gap-5 w-full">
              <div className="p-2.5 rounded-full w-fit bg-blue/15">
                <FaHandHoldingHeart className="text-blue text-26" />
              </div>
              <div>
                <p className="text-13 tab:text-base pb-1 lg:pb-1.5">
                  Total Amount of Help Given
                </p>
                <h6 className="text-26">
                  ${NumberWithCommas(data?.totalHelpGiven)}
                </h6>
              </div>
            </div>
            <div className="dashboard-card py-5 px-4 flex flex-col gap-3 lg:gap-5 w-full">
              <div className="p-2.5 rounded-full w-fit bg-lightGreen/15">
                <FaHandHoldingHeart className="text-lightGreen text-26" />
              </div>
              <div>
                <p className="text-13 tab:text-base pb-1 lg:pb-1.5">
                  Total Count of Help Given
                </p>
                <h6 className="text-26">
                  {NumberWithCommas(data?.numberOfRequest)}
                </h6>
              </div>
            </div>
          </div>
          {/* Dashboard Activity */}
          <div className="pt-30">
            <h6 className="text-lg mb-4">Latest Activity</h6>
            <div className="grid grid-cols-1 tab:grid-cols-2 gap-2.5 tab:gap-4">
              {data.helprequests && (
                <div className="dashboard-card border-l-[6px] border-blue px-3 py-4 tab:p-5">
                  <h6 className="tab:text-sm text-xs mb-1">
                    Last Payment Made
                  </h6>
                  <p className="tab:text-base text-xs">
                    {" "}
                    ${NumberWithCommas(data.helprequests?.doners?.amount)}{" "}
                    helped to pay {data.helprequests?.category?.name} for{" "}
                    {data.helprequests?.customerName} on{" "}
                    {moment(data.helprequests?.doners?.createdAt).format(
                      "DD MMM, YYYY"
                    )}{" "}
                    at{" "}
                    {moment(data.helprequests?.doners?.createdAt).format(
                      "hh:mm A"
                    )}
                  </p>
                </div>
              )}
              {/* <div className="dashboard-card border-l-[6px]  border-yellow px-3 py-4 tab:p-5">
                <h6 className="tab:text-sm text-xs mb-1">
                  New Vendor Request Received{" "}
                </h6>
                <p className="tab:text-base text-xs">
                  From Rent Ready on 15 Apr, 2024 at 2:30 PM{" "}
                </p>
              </div> */}

              {data.lastLoginTime && (
                <div className="dashboard-card border-l-[6px] border-success px-3 py-4 tab:p-5">
                  <h6 className="tab:text-sm text-xs mb-1">Last Logged In</h6>
                  <p className="tab:text-base text-xs">
                    {" "}
                    {moment(data.lastLoginTime).format("DD MMM, YYYY")} at{" "}
                    {moment(data.lastLoginTime).format("hh:mm A")}{" "}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GiveHelpDashboard;

import React, { useContext, useEffect, useRef, useState } from "react";
import { HiOutlineArrowSmUp } from "react-icons/hi";
import { CgSearch } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import Video from "../../assets/svg/background/work-video.svg";
import { IoPlayCircleOutline, IoPauseCircleOutline } from "react-icons/io5";
import UserCard from "../common/UserCard";
import { FaCircle, FaCircleCheck } from "react-icons/fa6";
import Accoudion from "../common/Accoudion";
import HelpService from "../service/help.service";
import Toaster from "../common/toaster";
import Loader from "../../assets/loader.gif";
import { AuthContext } from "../../context/authContext";

const Home = () => {
  const [searchText, setSearchText] = useState("");
  const [helpData, setHelpData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFAQ, setIsFAQ] = useState(false);
  const [role, setRole] = useState("");
  console.log("🚀  Home  role:", role);
  // Give Help Get Help
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
    setIsLoggedIn(Boolean(storedIsLoggedIn));

    const storedRole = localStorage.getItem("payyro-store");
    const role = JSON.parse(storedRole);
    setRole(role?.role);
  }, []);

  const videoRef = useRef(null);
  const [showVideo, setShowVideo] = useState(false);

  const handlePlayVideo = () => {
    setShowVideo(true); // Swap image with video
    setTimeout(() => videoRef.current?.play(), 100); // Ensure video starts playing
  };

  /* const togglePlayPause = () => {
    if (!isPlaying) {
      setIsPlaying(true); // Swap image with video
      setTimeout(() => videoRef.current?.play(), 100); // Ensure video starts playing
    } else {
      videoRef.current.pause();
      setIsPlaying(false); // Show image again if paused
    }
  }; */

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const navigate = useNavigate();

  const handleHelpRequestNavigate = () => {
    navigate("/help-request");
  };

  // Progressbar
  const barRef = useRef(null);

  useEffect(() => {
    const bar = barRef.current;
    if (bar) {
      bar.classList.remove("w-0");
      bar.classList.add("w-1/2");
    }
  }, []);

  const getAllHelpRequest = async () => {
    try {
      const body = {
        search: searchText,
        page: 1,
        limit: 6,
      };
      const response = await HelpService.getAllHelpRequest(body);
      setHelpData(response.data.data.slice(0, 6));
    } catch (error) {
      Toaster("error", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllHelpRequest();
  }, [searchText]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <div className="bg-primary/5">
        {/* Hero Section */}
        {isLoggedIn && role ? (
          <div className="hero-section relative">
            <div className="hero-overlay"></div>
            <div className="w-full flex items-center justify-center h-full relative top-9 md:top-0 z-50">
              <div className="max-w-[1000px] mx-auto w-full px-5">
                <div className="flex flex-col gap-4">
                  <h1 className="text-center">Give Help. Get Help. </h1>
                  <p className="text-sm sm:text-base md:text-xl text-center">
                    Payyro is the crowdfunding platform that helps financially
                    struggling families pay their overdue monthly bills.
                    Financial gifts from generous donors are sent directly to
                    vendors such as utility companies and property managers to
                    help with emergencies such as utility shutoffs and
                    evictions.
                  </p>
                </div>
                {/* <div className="mt-8 grid grid-cols-1 lg:grid-cols-3 gap-5">
                  <div className="rounded-lg border-2 border-white backdrop-blur-md bg-white/30 px-5 py-7 flex flex-col gap-4 items-center justify-between shadow-card">
                    <h6 className="text-sm md:text-15 text-center leading-[21.7px]">
                      Families in need can post Help Requests with details of
                      their overdue bills and personal situation.
                    </h6>
                    <div>
                      <div onClick={() => navigate("/login")}>
                        <button className="btn-default btn-primary">
                          Get Help
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="rounded-lg border-2 border-white backdrop-blur-md bg-white/30 px-5 py-7 flex flex-col gap-4 items-center justify-between shadow-card">
                    <h6 className="text-sm md:text-15 text-center leading-[21.7px]">
                      Donors provide urgent financial assistance with direct
                      vendor payments towards overdue bills of families in need.
                    </h6>
                    <div onClick={() => navigate("/help-request")}>
                      <button
                        className="btn-default btn-primary"
                        onClick={() => handleHelpRequestNavigate()}
                      >
                        Give Help
                      </button>
                    </div>
                  </div>
                  <div className="rounded-lg border-2 border-white backdrop-blur-md bg-white/30 px-5 py-7 flex flex-col gap-4 items-center justify-between shadow-card">
                    <h6 className="text-sm md:text-15 text-center leading-[21.7px]">
                      Vendors receive direct payments from donors for the
                      benefit of their low-income customers.
                    </h6>
                    <div onClick={() => navigate("/vendor-solution")}>
                      <button className="btn-default btn-primary">
                        Vendor Solutions
                      </button>
                    </div>
                  </div>
                </div> */}
                <h6 className="text-center text-15 leading-[19.5px] mt-8">
                  All Help Requests are verified to ensure that the requestor is
                  a current participant in a low-income program that provides
                  mandatory income verification such as: SNAP food stamps,
                  Section 8 housing, Medicaid, SSI disability, TANF welfare,
                  etc…
                </h6>
              </div>
            </div>
          </div>
        ) : (
          <div className="hero-section relative">
            <div className="hero-overlay"></div>
            <div className="w-full flex items-end h-full relative top-9 md:top-0 z-50">
              <div className="max-w-[1000px] mx-auto w-full px-5">
                <div className="flex flex-col gap-4">
                  <h1 className="text-center">Give Help. Get Help. </h1>
                  <p className="text-sm sm:text-base md:text-xl text-center">
                    Payyro is the crowdfunding platform that helps financially
                    struggling families pay their overdue monthly bills.
                    Financial gifts from generous donors are sent directly to
                    vendors such as utility companies and property managers to
                    help with emergencies such as utility shutoffs and
                    evictions.
                  </p>
                </div>
                <div className="mt-8 grid grid-cols-1 lg:grid-cols-3 gap-5">
                  <div className="rounded-lg border-2 border-white backdrop-blur-md bg-white/30 px-5 py-7 flex flex-col gap-4 items-center justify-between shadow-card">
                    <h6 className="text-sm md:text-15 text-center leading-[21.7px]">
                      Families in need can post Help Requests with details of
                      their overdue bills and personal situation.
                    </h6>
                    <div>
                      <div onClick={() => navigate("/login")}>
                        <button className="btn-default btn-primary">
                          Get Help
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="rounded-lg border-2 border-white backdrop-blur-md bg-white/30 px-5 py-7 flex flex-col gap-4 items-center justify-between shadow-card">
                    <h6 className="text-sm md:text-15 text-center leading-[21.7px]">
                      Donors provide urgent financial assistance with direct
                      vendor payments towards overdue bills of families in need.
                    </h6>
                    <div onClick={() => navigate("/help-request")}>
                      <button
                        className="btn-default btn-primary"
                        onClick={() => handleHelpRequestNavigate()}
                      >
                        Give Help
                      </button>
                    </div>
                  </div>
                  <div className="rounded-lg border-2 border-white backdrop-blur-md bg-white/30 px-5 py-7 flex flex-col gap-4 items-center justify-between shadow-card">
                    <h6 className="text-sm md:text-15 text-center leading-[21.7px]">
                      Vendors receive direct payments from donors for the
                      benefit of their low-income customers.
                    </h6>
                    <div onClick={() => navigate("/vendor-solution")}>
                      <button className="btn-default btn-primary">
                        Vendor Solutions
                      </button>
                    </div>
                  </div>
                </div>
                <h6 className="text-center text-15 leading-[19.5px] mt-8">
                  All Help Requests are verified to ensure that the requestor is
                  a current participant in a low-income program that provides
                  mandatory income verification such as: SNAP food stamps,
                  Section 8 housing, Medicaid, SSI disability, TANF welfare,
                  etc…
                </h6>
              </div>
            </div>
          </div>
        )}

        {/* Explore Help Requests... */}
        {role === "Give Help" && (
          <div className="pt-30 md:pt-60">
            <div className="containers">
              <div className="flex justify-between sm:flex-row flex-col gap-4 mb-[30px]">
                <h5>Explore Help Requests...</h5>
                <div className="relative flex items-center w-full max-w-[380px]">
                  <input
                    name="email"
                    type="text"
                    placeholder="Search Help Requests"
                    className="w-full text-input pe-11 text-input"
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <div className="absolute h-9 w-9 right-0.5 cursor-pointer border border-transparent rounded-md bg-white flex items-center justify-center">
                    <CgSearch className="text-2xl" />
                  </div>
                </div>
              </div>
              {isLoading ? (
                <div className="flex justify-center mt-3">
                  <img src={Loader} alt="Loader" className="w-14 h-14" />
                </div>
              ) : (
                <>
                  <UserCard data={helpData} role={role} />

                  <div className="mt-[26px] tab:mt-11 flex items-center justify-center">
                    <div
                      onClick={() => navigate("/give-help/help")}
                      className="btn-big btn-primary cursor-pointer"
                    >
                      Explore More
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {!isLoggedIn && !role && (
          <div className="pt-30 md:pt-60">
            <div className="containers">
              <div className="flex justify-between sm:flex-row flex-col gap-4 mb-[30px]">
                <h5>Explore Help Requests...</h5>
                <div className="relative flex items-center w-full max-w-[380px]">
                  <input
                    name="email"
                    type="text"
                    placeholder="Search Help Requests"
                    className="w-full text-input pe-11 text-input"
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <div className="absolute h-9 w-9 right-0.5 cursor-pointer border border-transparent rounded-md bg-white flex items-center justify-center">
                    <CgSearch className="text-2xl" />
                  </div>
                </div>
              </div>
              {isLoading ? (
                <div className="flex justify-center mt-3">
                  <img src={Loader} alt="Loader" className="w-14 h-14" />
                </div>
              ) : (
                <>
                  <UserCard data={helpData} />

                  <div className="mt-[26px] tab:mt-11 flex items-center justify-center">
                    <div
                      onClick={() => navigate("/help-request")}
                      className="btn-big btn-primary cursor-pointer"
                    >
                      Explore More
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {/* Help Card */}
        {role === "Get Help" && (
          <div className="pt-30 md:pt-60 relative z-10">
            <div className="containers">
              <div className="help-card need-help">
                <div className="relative z-50 flex items-center gap-4 justify-center flex-col">
                  <h2 className="text-white font-normal text-center">
                    I need help.  <br className="md:hidden" /> Do I qualify? 
                  </h2>
                  <p className="text-base text-white">
                    How do I make a Help Request?
                  </p>
                  <div>
                    <div onClick={() => navigate("/get-help/user-get-help")}>
                      <button className="btn-default btn-outline-white">
                        Get Help
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {!isLoggedIn && !role && (
          <div className="pt-30 md:pt-60 relative z-10">
            <div className="containers">
              <div className="help-card need-help">
                <div className="relative z-50 flex items-center gap-4 justify-center flex-col">
                  <h2 className="text-white font-normal text-center">
                    I need help.  <br className="md:hidden" /> Do I qualify? 
                  </h2>
                  <p className="text-base text-white">
                    How do I make a Help Request?
                  </p>
                  <div>
                    <div onClick={() => navigate("/login")}>
                      <button className="btn-default btn-outline-white">
                        Get Help
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* How Payyro Works */}
        {!isLoggedIn && !role && (
          <section className="relative pt-30 tab:pt-60" id="works">
            <div className="work-overlay"></div>
            <div className="containers relative z-10">
              <div>
                <h5>How Payyro Works</h5>
              </div>
              <div>
                <div className="py-30 relative">
                  {/* Initially Show Image, Replace with Video on Click */}
                  <div className="relative cursor-pointer">
                    {!showVideo ? (
                      <>
                        <img
                          src={Video}
                          alt="Work Video"
                          className="w-full rounded-md"
                          onClick={handlePlayVideo}
                        />
                        {/* Play Button Overlay */}
                        <div
                          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center gap-2 w-fit p-2 md:py-4 md:px-[26px] rounded-md md:rounded-full bg-white/60 backdrop-blur-md"
                          onClick={handlePlayVideo}
                        >
                          <IoPlayCircleOutline className="text-primary text-xl md:text-[28px]" />
                          <h6 className="leading-[24.52px] text-base md:text-xl">
                            Play 1 min video
                          </h6>
                        </div>
                      </>
                    ) : (
                      <video
                        ref={videoRef}
                        src={"https://www.w3schools.com/html/mov_bbb.mp4"}
                        className="w-full h-[551px] rounded-md border"
                        controls
                      />
                    )}
                  </div>
                </div>
                <div>
                  <h6 className="font-bold text-base mb-5">
                    Payyro safely and securely matches families with urgent
                    financial needs with generous members of the community that
                    assist with common consumer bills.
                  </h6>
                  <p className="mb-4 text-14">
                    1)   Families in financial distress can post Help Requests
                    and describe their personal financial situation.
                  </p>
                  <p className="mb-4 text-14">
                    2)   Donors make payments that go directly to the vendors
                    (utility company, landlords, etc…). 
                  </p>
                  <h6 className="font-bold text-xs mb-5 bg-primary/20 rounded-md p-2 leading-[14.71px]">
                    ** NO MONEY EVER GOES DIRECTLY TO THE PEOPLE POSTING THE
                    HELP REQUESTS.  ALL PAYMENTS ARE SENT DIRECTLY TO THE
                    VENDOR.
                  </h6>
                  <p className="text-14">
                    3)   Vendors that are owed money receive payments for the
                    accounts of their low-income customers without the expense
                    and burden of traditional collections efforts.
                  </p>
                </div>
              </div>
            </div>
          </section>
        )}

        {isLoggedIn && role && (
          <section className="relative pt-30 tab:pt-60" id="works">
            {/* <div className="work-overlay"></div> */}
            <div className="containers relative z-10">
              <div>
                <h5>How Payyro Works</h5>
              </div>
              <div>
                <div className="py-30 relative">
                  {/* Initially Show Image, Replace with Video on Click */}
                  <div className="relative cursor-pointer">
                    {!showVideo ? (
                      <>
                        <img
                          src={Video}
                          alt="Work Video"
                          className="w-full rounded-md"
                          onClick={handlePlayVideo}
                        />
                        {/* Play Button Overlay */}
                        <div
                          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center gap-2 w-fit p-2 md:py-4 md:px-[26px] rounded-md md:rounded-full bg-white/60 backdrop-blur-md"
                          onClick={handlePlayVideo}
                        >
                          <IoPlayCircleOutline className="text-primary text-xl md:text-[28px]" />
                          <h6 className="leading-[24.52px] text-base md:text-xl">
                            Play 1 min video
                          </h6>
                        </div>
                      </>
                    ) : (
                      <video
                        ref={videoRef}
                        src={"https://www.w3schools.com/html/mov_bbb.mp4"}
                        className="w-full h-[551px] rounded-md border"
                        controls
                      />
                    )}
                  </div>
                </div>
                <div>
                  <h6 className="font-bold text-base mb-5">
                    Payyro safely and securely matches families with urgent
                    financial needs with generous members of the community that
                    assist with common consumer bills.
                  </h6>
                  <p className="mb-4 text-14">
                    1)   Families in financial distress can post Help Requests
                    and describe their personal financial situation.
                  </p>
                  <p className="mb-4 text-14">
                    2)   Donors make payments that go directly to the vendors
                    (utility company, landlords, etc…). 
                  </p>
                  <h6 className="font-bold text-xs mb-5 bg-primary/20 rounded-md p-2 leading-[14.71px]">
                    ** NO MONEY EVER GOES DIRECTLY TO THE PEOPLE POSTING THE
                    HELP REQUESTS.  ALL PAYMENTS ARE SENT DIRECTLY TO THE
                    VENDOR.
                  </h6>
                  <p className="text-14">
                    3)   Vendors that are owed money receive payments for the
                    accounts of their low-income customers without the expense
                    and burden of traditional collections efforts.
                  </p>
                </div>
              </div>
            </div>
          </section>
        )}

        {!isLoggedIn && !role && (
          <div className="pt-30 md:pt-60">
            <div className="containers">
              <div className="user-card">
                <div className="bg-white rounded-xl shadow-card2 flex flex-col justify-between">
                  <div className="px-[20px] pt-[20px] md:pt-30 md:px-[26px]">
                    <div>
                      <h6 className="font-bold text-sm md:text-base mb-4">
                        Get Help - Here is what to expect when posting a Help
                        Request
                      </h6>
                      <div className="flex items-center gap-2.5 mb-4">
                        <div className="w-5">
                          <FaCircleCheck className="text-success text-base md:text-lg" />
                        </div>
                        <p className="leading-[17.17px] text-xs md:text-sm">
                          Click the ‘Get Help’ button and answer a few questions
                          to get started
                        </p>
                      </div>
                      <div className="flex items-center gap-2.5 mb-5">
                        <div className="w-5">
                          <FaCircleCheck className="text-success text-base md:text-lg" />
                        </div>
                        <p className="leading-[17.17px] text-xs md:text-sm">
                          In your Help Request description, share the reason you
                          are fundraising in 1-3 paragraphs
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="leading-[17.17px] text-xs md:text-sm mb-4">
                        Add details such as:
                      </p>
                      <div className="flex items-center gap-2.5 mb-4">
                        <div className="w-5">
                          <FaCircle className="text-yellow text-base md:text-lg" />
                        </div>
                        <p className="leading-[17.17px] text-xs md:text-sm">
                          Who are you and why you need urgent financial help
                        </p>
                      </div>
                      <div className="flex items-center gap-2.5 mb-5">
                        <div className="w-5">
                          <FaCircle className="text-yellow text-base md:text-lg" />
                        </div>
                        <p className="leading-[17.17px] text-xs md:text-sm">
                          A main image or video that represents your Help
                          Request
                        </p>
                      </div>
                    </div>
                    <div className="mb-[22px]">
                      <p className="leading-[17.17px] text-xs md:text-sm font-bold mb-4">
                        Need more help posting an effective Help Request? 
                      </p>
                      <p className="leading-[17.17px] text-xs md:text-sm">
                        Watch our video on writing a compelling request story to
                        get inspiration.
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-center border-t border-primary/20 pt-4 pb-5 md:pt-5 md:pb-[30px]">
                    <div>
                      {/* <div onClick={() => navigate("/get-help")}> */}
                      <div onClick={() => navigate("/login")}>
                        <button className="btn-default btn-primary">
                          Get Help
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white rounded-xl shadow-card2 flex flex-col justify-between">
                  <div className="px-[20px] pt-[20px] md:pt-30 md:px-[26px]">
                    <div>
                      <h6 className="font-bold text-sm md:text-base mb-4">
                        Give Help – Here is what to expect when providing help
                      </h6>
                      <div className="flex items-center gap-2.5 mb-4">
                        <div className="w-5">
                          <FaCircleCheck className="text-success text-base md:text-lg" />
                        </div>
                        <p className="leading-[17.17px] text-xs md:text-sm">
                          Click the “Give Help” button
                        </p>
                      </div>
                      <div className="flex items-center gap-2.5 mb-5">
                        <div className="w-5">
                          <FaCircleCheck className="text-success text-base md:text-lg" />
                        </div>
                        <p className="leading-[17.17px] text-xs md:text-sm">
                          You can create an account and track your generous
                          support
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="leading-[17.17px] text-xs md:text-sm mb-4">
                        OR
                      </p>
                      <div className="flex items-center gap-2.5 mb-4">
                        <div className="w-5">
                          <FaCircleCheck className="text-success text-base md:text-lg" />
                        </div>
                        <p className="leading-[17.17px] text-xs md:text-sm">
                          You can provide help as a Guest
                        </p>
                      </div>
                      <div className="flex items-center gap-2.5 mb-5">
                        <div className="w-5">
                          <FaCircleCheck className="text-success text-base md:text-lg" />
                        </div>
                        <p className="leading-[17.17px] text-xs md:text-sm">
                          Your financial support will be sent directly to the
                          Vendor. 
                        </p>
                      </div>
                    </div>
                    <div className="mb-[22px]">
                      <p className="leading-[17.17px] text-xs md:text-sm font-bold mb-4">
                        No money is sent directly to the person making the
                        request.
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-center border-t border-primary/20 pt-4 pb-5 md:pt-5 md:pb-[30px]">
                    <div onClick={() => navigate("/help-request")}>
                      <button className="btn-default btn-primary">
                        Give Help
                      </button>
                    </div>
                  </div>
                </div>
                <div className="bg-white rounded-xl shadow-card2 flex flex-col justify-between">
                  <div className="px-[20px] pt-[20px] md:pt-30 md:px-[26px]">
                    <div>
                      <h6 className="font-bold text-sm md:text-base mb-4">
                        Vendor Solutions - Here is what to expect as a
                        participating vendor
                      </h6>
                      <div className="flex items-center gap-2.5 mb-4">
                        <div className="w-5">
                          <FaCircleCheck className="text-success text-base md:text-lg" />
                        </div>
                        <p className="leading-[17.17px] text-xs md:text-sm">
                          Create Help Requests on behalf of your delinquent
                          customers.
                        </p>
                      </div>
                      <div className="flex items-center gap-2.5 mb-5 ms-7">
                        <p className="leading-[17.17px] text-xs md:text-sm">
                          Help Requests are a summary of your unpaid bills. Your
                          customer can then review and publicly publish the Help
                          Request.
                        </p>
                      </div>
                      <div className="flex items-center gap-2.5 mb-5 ms-7">
                        <p className="leading-[17.17px] text-xs md:text-sm">
                          As generous members of the community make donations to
                          pay these bills, the money is sent directly to you as
                          the Vendor.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center border-t border-primary/20 pt-4 pb-5 md:pt-5 md:pb-[30px]">
                    <div onClick={() => navigate("/vendor-solution")}>
                      <button className="btn-default btn-primary">
                        Vendor Solutions
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {role === "Get Help" && (
          <div className="pt-30 md:pt-60 py-10">
            <div className="containers">
              <div className="user-card">
                <div className="bg-white rounded-xl shadow-card2 flex flex-col justify-between">
                  <div className="px-[20px] pt-[20px] md:pt-30 md:px-[26px]">
                    <div>
                      <h6 className="font-bold text-sm md:text-base mb-4">
                        Get Help - Here is what to expect when posting a Help
                        Request
                      </h6>
                      <div className="flex items-center gap-2.5 mb-4">
                        <div className="w-5">
                          <FaCircleCheck className="text-success text-base md:text-lg" />
                        </div>
                        <p className="leading-[17.17px] text-xs md:text-sm">
                          Click the ‘Get Help’ button and answer a few questions
                          to get started
                        </p>
                      </div>
                      <div className="flex items-center gap-2.5 mb-5">
                        <div className="w-5">
                          <FaCircleCheck className="text-success text-base md:text-lg" />
                        </div>
                        <p className="leading-[17.17px] text-xs md:text-sm">
                          In your Help Request description, share the reason you
                          are fundraising in 1-3 paragraphs
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="leading-[17.17px] text-xs md:text-sm mb-4">
                        Add details such as:
                      </p>
                      <div className="flex items-center gap-2.5 mb-4">
                        <div className="w-5">
                          <FaCircle className="text-yellow text-base md:text-lg" />
                        </div>
                        <p className="leading-[17.17px] text-xs md:text-sm">
                          Who are you and why you need urgent financial help
                        </p>
                      </div>
                      <div className="flex items-center gap-2.5 mb-5">
                        <div className="w-5">
                          <FaCircle className="text-yellow text-base md:text-lg" />
                        </div>
                        <p className="leading-[17.17px] text-xs md:text-sm">
                          A main image or video that represents your Help
                          Request
                        </p>
                      </div>
                    </div>
                    <div className="mb-[22px]">
                      <p className="leading-[17.17px] text-xs md:text-sm font-bold mb-4">
                        Need more help posting an effective Help Request? 
                      </p>
                      <p className="leading-[17.17px] text-xs md:text-sm">
                        Watch our video on writing a compelling request story to
                        get inspiration.
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-center border-t border-primary/20 pt-4 pb-5 md:pt-5 md:pb-[30px]">
                    <div>
                      {/* <div onClick={() => navigate("/get-help")}> */}
                      <div onClick={() => navigate("/get-help/user-get-help")}>
                        <button className="btn-default btn-primary">
                          Get Help
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {role === "Give Help" && (
          <div className="pt-30 md:pt-60">
            <div className="containers">
              <div className="user-card">
                <div className="bg-white rounded-xl shadow-card2 flex flex-col justify-between">
                  <div className="px-[20px] pt-[20px] md:pt-30 md:px-[26px]">
                    <div>
                      <h6 className="font-bold text-sm md:text-base mb-4">
                        Give Help – Here is what to expect when providing help
                      </h6>
                      <div className="flex items-center gap-2.5 mb-4">
                        <div className="w-5">
                          <FaCircleCheck className="text-success text-base md:text-lg" />
                        </div>
                        <p className="leading-[17.17px] text-xs md:text-sm">
                          Click the “Give Help” button
                        </p>
                      </div>
                      <div className="flex items-center gap-2.5 mb-5">
                        <div className="w-5">
                          <FaCircleCheck className="text-success text-base md:text-lg" />
                        </div>
                        <p className="leading-[17.17px] text-xs md:text-sm">
                          You can create an account and track your generous
                          support
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="leading-[17.17px] text-xs md:text-sm mb-4">
                        OR
                      </p>
                      <div className="flex items-center gap-2.5 mb-4">
                        <div className="w-5">
                          <FaCircleCheck className="text-success text-base md:text-lg" />
                        </div>
                        <p className="leading-[17.17px] text-xs md:text-sm">
                          You can provide help as a Guest
                        </p>
                      </div>
                      <div className="flex items-center gap-2.5 mb-5">
                        <div className="w-5">
                          <FaCircleCheck className="text-success text-base md:text-lg" />
                        </div>
                        <p className="leading-[17.17px] text-xs md:text-sm">
                          Your financial support will be sent directly to the
                          Vendor. 
                        </p>
                      </div>
                    </div>
                    <div className="mb-[22px]">
                      <p className="leading-[17.17px] text-xs md:text-sm font-bold mb-4">
                        No money is sent directly to the person making the
                        request.
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-center border-t border-primary/20 pt-4 pb-5 md:pt-5 md:pb-[30px]">
                    <div onClick={() => navigate("/give-help/help")}>
                      <button className="btn-default btn-primary">
                        Give Help
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {role === "Vendor" && (
          <div className="pt-30 md:pt-60">
            <div className="containers">
              <div className="user-card py-10">
                <div className="bg-white rounded-xl shadow-card2 flex flex-col justify-between">
                  <div className="px-[20px] pt-[20px] md:pt-30 md:px-[26px]">
                    <div>
                      <h6 className="font-bold text-sm md:text-base mb-4">
                        Vendor Solutions - Here is what to expect as a
                        participating vendor
                      </h6>
                      <div className="flex items-center gap-2.5 mb-4">
                        <div className="w-5">
                          <FaCircleCheck className="text-success text-base md:text-lg" />
                        </div>
                        <p className="leading-[17.17px] text-xs md:text-sm">
                          Create Help Requests on behalf of your delinquent
                          customers.
                        </p>
                      </div>
                      <div className="flex items-center gap-2.5 mb-5 ms-7">
                        <p className="leading-[17.17px] text-xs md:text-sm">
                          Help Requests are a summary of your unpaid bills. Your
                          customer can then review and publicly publish the Help
                          Request.
                        </p>
                      </div>
                      <div className="flex items-center gap-2.5 mb-5 ms-7">
                        <p className="leading-[17.17px] text-xs md:text-sm">
                          As generous members of the community make donations to
                          pay these bills, the money is sent directly to you as
                          the Vendor.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center border-t border-primary/20 pt-4 pb-5 md:pt-5 md:pb-[30px]">
                    <div onClick={() => navigate("/vendor/dashboard")}>
                      <button className="btn-default btn-primary">
                        Vendor Solutions
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {!isLoggedIn && !role && (
          <div className="pt-30 md:pt-60 relative z-10">
            <div className="containers">
              <div className="help-card help-families">
                <div className="relative z-50 flex items-center gap-4 justify-center flex-col">
                  <h2 className="text-white font-normal text-center">
                    Help families in your local community.
                  </h2>
                  <p className="text-base text-white">
                    Help families with urgent financial needs avoid evictions,
                    utility shutoffs, and other destabilizing outcomes.
                  </p>
                  <div onClick={() => navigate("/help-request")}>
                    <button className="btn-big btn-success">Give Help</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {role === "Give Help" && (
          <div className="pt-30 md:pt-60 relative z-10">
            <div className="containers">
              <div className="help-card help-families">
                <div className="relative z-50 flex items-center gap-4 justify-center flex-col">
                  <h2 className="text-white font-normal text-center">
                    Help families in your local community.
                  </h2>
                  <p className="text-base text-white">
                    Help families with urgent financial needs avoid evictions,
                    utility shutoffs, and other destabilizing outcomes.
                  </p>
                  <div onClick={() => navigate("/give-help/help")}>
                    <button className="btn-big btn-success">Give Help</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Faq */}
        {isFAQ && (
          <section className="pt-30 md:pt-60 bg-white relative" id="faq">
            <div className="faq-overlay"></div>
            <div className="containers relative z-10">
              <div>
                <h5>How Payyro Works</h5>
              </div>
              <div>
                <Accoudion setIsFAQ={setIsFAQ} />
              </div>
            </div>
          </section>
        )}

        {!isLoggedIn && !role && (
          <div className="py-30 md:py-60 relative z-10 bg-white">
            <div className="containers">
              <div className="help-card help-give">
                <div className="relative z-50 flex items-center gap-4 justify-center flex-col">
                  <h2 className="text-primary font-normal text-center">
                    Give Help. Get Help.
                  </h2>
                  <p className="text-base text-primary/80 text-center">
                    Payyro stabilizes vulnerable families by using crowdfunding
                    to match urgent financial needs with generous donors in the
                    community. Vendors can continue providing critical services
                    to these families.
                  </p>
                  <div onClick={() => navigate("/help-request")}>
                    <button className="btn-big btn-primary">Give Help</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {role === "Give Help" && (
          <div className="py-30 md:py-60 relative z-10 bg-white">
            <div className="containers">
              <div className="help-card help-give">
                <div className="relative z-50 flex items-center gap-4 justify-center flex-col">
                  <h2 className="text-primary font-normal text-center">
                    Give Help. Get Help.
                  </h2>
                  <p className="text-base text-primary/80 text-center">
                    Payyro stabilizes vulnerable families by using crowdfunding
                    to match urgent financial needs with generous donors in the
                    community. Vendors can continue providing critical services
                    to these families.
                  </p>
                  <div onClick={() => navigate("/give-help/help")}>
                    <button className="btn-big btn-primary">Give Help</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Click To Top */}
      <div
        onClick={scrollToTop}
        className="fixed right-2.5 md:right-10 bottom-10 h-8 w-8 flex items-center justify-center bg-primary rounded cursor-pointer border group duration-200 border-white z-80"
      >
        <HiOutlineArrowSmUp className="text-white text-2xl" />
      </div>
    </>
  );
};

export default Home;

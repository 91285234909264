import React from "react";
import { Navigate, useRoutes } from "react-router";
import { lazy } from "react";
import NotFound from "../../app/common/NotFound";
import SetUpProfile from "../../app/auth/admin/SetUpProfile";
import EffectiveBio from "../../app/user/gethelp/EffectiveBio";

const SuspenseLoader = (Component) => (props) =>
  (
    <React.Suspense fallback={<withLoader />}>
      <Component {...props} />
    </React.Suspense>
  );

const HelpRequest = SuspenseLoader(
  lazy(() => import("../../app/public/HelpRequest"))
);
const RequestDetail = SuspenseLoader(
  lazy(() => import("../../app/public/RequestDetail"))
);
const UserProfile = SuspenseLoader(
  lazy(() => import("../../app/public/UserProfile"))
);
const HelpPaymentForm = SuspenseLoader(
  lazy(() => import("../../app/public/HelpPaymentForm"))
);
const GetHelp = SuspenseLoader(lazy(() => import("../../app/public/GetHelp")));
const Login = SuspenseLoader(lazy(() => import("../../app/auth/admin/Login")));
const SignUp = SuspenseLoader(
  lazy(() => import("../../app/auth/admin/SignUp"))
);
const ForgotPassword = SuspenseLoader(
  lazy(() => import("../../app/auth/admin/ForgotPassword"))
);
const ResetPassword = SuspenseLoader(
  lazy(() => import("../../app/auth/admin/ResetPassword"))
);
const VendorSignUp = SuspenseLoader(
  lazy(() => import("../../app/auth/vendor/VendorSignUp"))
);
const VendorLogin = SuspenseLoader(
  lazy(() => import("../../app/auth/vendor/VendorLogin"))
);
const VendorSolutions = SuspenseLoader(
  lazy(() => import("../../app/public/vendor-solutions/VendorSolutions"))
);
const VendorSolutionsLeArnMore = SuspenseLoader(
  lazy(() =>
    import("../../app/public/vendor-solutions/VendorSolutionsLeArnMore")
  )
);
const PublicLayout = SuspenseLoader(
  lazy(() => import("../../app/PublicLayout"))
);
const Home = SuspenseLoader(lazy(() => import("../../app/public/Home")));

function RedirectionWrapper({ to }) {
  const queryString = window.location.search;
  if (queryString) {
    return <Navigate to={`${to}${queryString}`} />;
  }
  return <Navigate to={to} />;
}

const routes = () => [
  {
    path: "/",
    element: <PublicLayout />,
    children: [
      {
        element: <RedirectionWrapper to="/" />,
        index: true,
        element: <Home />,
      },
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/help-request",
        element: <HelpRequest />,
      },
      {
        path: "/request-detail/:id",
        element: <RequestDetail />,
      },
      {
        path: "/user-profile/:id",
        element: <UserProfile />,
      },
      {
        path: "/help-payment-form/:id",
        element: <HelpPaymentForm />,
      },
      {
        path: "/get-help",
        element: <GetHelp />,
      },
      {
        path: "/vendor-solution",
        element: <VendorSolutions />,
      },
      {
        path: "/vendor-solutions/vendor-solutions-learn-more",
        element: <VendorSolutionsLeArnMore />,
      },
    ],
  },
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/setupprofile/:type",
    element: <SetUpProfile />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password/:token",
    element: <ResetPassword />,
  },
  {
    path: "/vendor-signup",
    element: <VendorSignUp />,
  },
  {
    path: "/vendor-login",
    element: <VendorLogin />,
  },
  {
    path: "/effective-bio",
    element: <EffectiveBio />,
  },
  // { path: "*", element: <NotFound /> },
];

export default function PublicRoutes({ isLoggedIn }) {
  return useRoutes(routes(isLoggedIn));
}

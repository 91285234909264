import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import { LuUpload } from "react-icons/lu";
import DocumentLoader from "../../../assets/documentLoader.gif";
import { BsFileEarmarkPdfFill, BsFileEarmarkWordFill } from "react-icons/bs";
import { AiFillFileExcel } from "react-icons/ai";
import Loader from "../../../assets/loader.gif";
import { BiSolidFileJpg, BiSolidFilePng } from "react-icons/bi";

const UploadDocument = ({
  open,
  handleClickClose,
  documentRef,
  handleFileUpload,
  uploadProgress,
  uploadedFiles,
  isFileUpload,
  handleDocumentSubmit,
  documentLoader,
  handleDrop,
}) => {
  const totalProgress = Object.values(uploadProgress).reduce(
    (acc, progress) => acc + progress,
    0
  );
  const averageProgress =
    Object.keys(uploadProgress).length > 0
      ? Math.round(totalProgress / Object.keys(uploadProgress).length)
      : 0;

  return (
    <div>
      <div className="fixed inset-0 z-[999] bg-black/50 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen px-4">
          <div
            className="dashboard-card overflow-hidden max-w-[652px] w-full min-w-[350px]"
            ref={documentRef}
          >
            <div className="b-b border-primary/20 tab:px-26 px-4 py-4 tab:py-5">
              <div className="flex justify-between items-center">
                <h5 className="tab:text-xl text-sm">Add Income Document</h5>
                <div className="cursor-pointer" onClick={handleClickClose}>
                  <IoCloseOutline className="h-4 w-4" />
                </div>
              </div>
            </div>
            <div className="tab:px-26 px-4 pt-4 tab:pt-26">
              <div className="flex flex-col gap-4">
                <p className="text-14">
                  Upload documents that show your participation in government
                  assistance programs such as: SNAP food stamps, Section 8
                  housing, Medicaid, SSI disability, TANF, etc…
                </p>
                {!isFileUpload && (
                  <div
                    className=""
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => handleDrop(e, "document")}
                  >
                    <label
                      className="border-2 mb-0 border-dashed border-primary/20 rounded-md w-full h-24 tab:h-[101px] flex flex-col items-center justify-center gap-1.5 cursor-pointer"
                      htmlFor="bill-upload"
                    >
                      <div className="h-6 w-6">
                        <LuUpload className="h-6 w-6" />
                      </div>
                      <h6 className="text-xs font-normal">
                        Browse or Drag income documents to upload
                      </h6>
                    </label>
                    <input
                      id="bill-upload"
                      type="file"
                      className="hidden"
                      onChange={handleFileUpload}
                      multiple
                    />
                  </div>
                )}
                <div className="mt-2 flex flex-col gap-y-1 max-h-56 overflow-auto">
                  {averageProgress > 0 && averageProgress < 100 ? (
                    <label
                      className="border-2 mb-0 border-dashed border-primary/20 rounded-md w-full h-36 tab:h-[120px] flex flex-col items-center justify-center gap-1.5 cursor-pointer"
                      htmlFor="bill-upload"
                    >
                      <img
                        src={DocumentLoader}
                        alt="DocumentLoader"
                        className="h-14 w-14"
                      />
                      <div className="w-[50%] bg-gray-200 rounded-full h-1">
                        <div
                          className="bg-primary h-1 rounded-full"
                          style={{ width: `${averageProgress}%` }}
                        ></div>
                      </div>
                      <p className="text-primary text-12 mt-2">
                        Uploading files... {averageProgress}%
                      </p>
                    </label>
                  ) : null}

                  {Object.entries(uploadProgress).map(
                    ([fileName, progress]) => {
                      return (
                        <div key={fileName} className="mt-2">
                          {progress === 100 && (
                            <div className="flex flex-col items-center">
                              <div className="flex items-center">
                                <span
                                  className={`${
                                    fileName.endsWith(".pdf")
                                      ? "text-red-500"
                                      : fileName.endsWith(".xls") ||
                                        fileName.endsWith(".xlsx")
                                      ? "text-green-500"
                                      : fileName.endsWith(".jpg") ||
                                        fileName.endsWith(".jpeg")
                                      ? "text-purple-400"
                                      : fileName.endsWith(".png") ||
                                        fileName.endsWith(".png")
                                      ? "text-yellow"
                                      : "text-blue"
                                  } mr-2 text-xl`}
                                >
                                  {fileName.endsWith(".pdf") ? (
                                    <BsFileEarmarkPdfFill />
                                  ) : fileName.endsWith(".xls") ||
                                    fileName.endsWith(".xlsx") ? (
                                    <AiFillFileExcel />
                                  ) : fileName.endsWith(".jpg") ||
                                    fileName.endsWith(".jpeg") ? (
                                    <BiSolidFileJpg />
                                  ) : fileName.endsWith(".png") ||
                                    fileName.endsWith(".png") ? (
                                    <BiSolidFilePng />
                                  ) : (
                                    <BsFileEarmarkWordFill />
                                  )}
                                </span>
                                <span className="text-16 text-primary font-bold">
                                  {fileName}
                                </span>
                                <span className="ml-2 text-primary text-14">
                                  Uploaded (
                                  {(
                                    uploadedFiles.find(
                                      (f) => f.name === fileName
                                    )?.size /
                                    1024 /
                                    1024
                                  ).toFixed(1)}{" "}
                                  MB)
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
            <div className="b-b border-primary/20 pb-26"></div>
            <div className="text-end tab:py-5 tab:px-26 py-4 px-4">
              {documentLoader ? (
                <div className="flex justify-end">
                  <button
                    onClick={handleDocumentSubmit}
                    type="submit"
                    className="btn-default btn-outline-primary h-[37px] w-[147px] tab:h-[49px] tab:w-[167px] flex justify-center"
                  >
                    <img src={Loader} alt="Loader" className="h-8 w-8" />
                  </button>
                </div>
              ) : (
                <button
                  onClick={handleDocumentSubmit}
                  type="submit"
                  className="btn-big btn-outline-primary h-[37px] w-[147px] tab:h-[49px] tab:w-[167px]"
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadDocument;
